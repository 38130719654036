import { CancelTokenSource } from 'axios'
import { action, makeAutoObservable } from 'mobx'
import APIClient from '../lib/APIClient'
import whenChangedParameters from '../utils/whenChangedParameters'
import BaseAPI from './API/BaseAPI'
import ICommunity from '../interfaces/ICommunity'

export interface ITopCommunityInfo extends ICommunity {
  position: number
  tags: Array<{ tagID: string, position: number }>
  suggestedTags: Array<{ tagID: string, position: number }>
  socials: Map<string, string>
}

export interface IStatistics {
  similar: Array<ITopCommunityInfo>
  usersCount: number
  usersCountDeltaDay: number
  usersCountDeltaMonth: number
  usersCountDeltaWeek: number
  usersCountRetrospective: Array<{ date: string, value: number }>
}

export default class RatingTopCommunityStore {
  public isLoading: boolean = false
  public community: ITopCommunityInfo = null
  public statistics: IStatistics = null
  private _cancelSource: CancelTokenSource = null

  constructor () {
    makeAutoObservable(this)
  }

  @action
  @whenChangedParameters
  public async load (socialType: string, groupID: string, screenName: string): Promise<void> {
    this.isLoading = true
    if (this._cancelSource) this._cancelSource.cancel()
    this._cancelSource = BaseAPI.cancelSource()

    try {
      const data = await APIClient.get('rating/top/community', {
        fields: 'cid,socialType,groupID,description',
        socialType,
        groupID,
        screenName
      }, this._cancelSource.token)

      const community = data.data.data

      this.community = community

      console.log('RatingTopCommunityStore community', community)

      const statisticsData = await APIClient.get('rating/info/community', {
        cid: community.cid
      }, this._cancelSource.token)

      const statistics = statisticsData.data.data

      this.statistics = statistics

      console.log('RatingTopCommunityStore statistics', statistics)

      this.isLoading = false
    } catch (e) {
      if (e.__CANCEL__) return

      console.log('RatingTopCommunityStore', e.response)
      // validationErrors: e.response.data.meta.errors
      return
    }
  }
}
