import React, { Component, ReactNode } from 'react'

import './Footer.scss'
import Icon from '../Icon/Icon'
import moment from 'moment'

class Footer extends Component {

  render (): ReactNode {
    return (
      <div className='footer'>
        <div className='footer__container'>
          <span className='footer__text'>{moment().format('YYYY')} &copy; JagaJam</span>
          <a className='footer__link' href='https://jagajam.io/docs/Media_Consulting_LLC_License_Agreement_Ru_30122020.pdf' target='_blank'>Условия использования</a>
          <a className='footer__link' href='https://jagajam.io/#contacts' target='_blank'>Контакты</a>
          <div className='footer__social'>
            <a className='footer__link' href='https://vk.com/jagajam' target='_blank'><Icon className='footer__icon' icon='vk' /></a>
            <a className='footer__link' href='https://www.facebook.com/jagajam' target='_blank'><Icon className='footer__icon' icon='fb' /></a>
            <a className='footer__link' href='https://www.instagram.com/jagajam_media' target='_blank'><Icon className='footer__icon' icon='ig' /></a>
            <a className='footer__link' href='https://ok.ru/jagajam' target='_blank'><Icon className='footer__icon' icon='ok' /></a>
            <a className='footer__link' href='https://t.me/jagajam' target='_blank'><Icon className='footer__icon' icon='tg' /></a>
            <a className='footer__link' href='https://twitter.com/jagajam_media' target='_blank'><Icon className='footer__icon' icon='tw' /></a>
            <a className='footer__link' href='https://www.youtube.com/c/jagajam_media' target='_blank'><Icon className='footer__icon' icon='yt' /></a>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
