import React, { ChangeEventHandler, Component, ReactNode } from 'react'

import uuid from '../../behaviors/Uuid/Uuid'

import './Checkbox.scss'

interface IProps {
  /**
   * Текст
   */
  children?: ReactNode
  /**
   * Аттрибут Checked
   */
  checked: boolean
  /**
   * Обработчик onChange, вызывается при изменении состояния
   */
  onChange?: ChangeEventHandler<HTMLInputElement>
}

/**
 * Компонент Checkbox
 */
class Checkbox extends Component<IProps> {
  state = {
    uuid: uuid()
  }

  /* handleClick = (e) => {
    const { checked, onChange } = this.props
    let value = checked

    if (value) e.target.value = 'on'
    else e.target.value = ''

    if (onChange) onChange(e, value)
    e.stopPropagation()
  } */

  render (): JSX.Element {
    const { checked, children } = this.props

    return (
      <div className='checkbox'>
        <input
          id={this.state.uuid}
          className='checkbox__input'
          checked={Boolean(checked)}
          type='checkbox'
          onClick={e => { e.stopPropagation() }}
          readOnly
        />
        <label htmlFor={this.state.uuid}>
          {children && <span className='checkbox__label'>{children}</span>}
        </label>
      </div>
    )
  }
}

export default Checkbox
