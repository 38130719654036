// import { enableStaticRendering } from 'mobx-react'
import { RouterStore } from 'mobx-react-router'
import ProfileStore from './ProfileStore'
import AccountsStore from './AccountsStore'
import RatingTopStore from './RatingTopStore'
import SearchStore from './SearchStore'
import RatingTopCommunityStore from './RatingTopCommunityStore'
import RatingTagsStore from './RatingTagsStore'

type StoresMap = {[T in Stores]: T}

// enableStaticRendering(true)

export enum Stores {
  ROUTING = 'routing',
  SEARCH_STORE = 'searchStore',
  RATING_TOP_STORE = 'ratingTopStore',
  RATING_TOP_COMMUNITY_STORE = 'ratingTopCommunityStore',
  RATING_TAGS_STORE = 'ratingTagsStore',
  PROFILE_STORE = 'profileStore',
  ACCOUNTS_STORE = 'accountsStore'
}

const stores = {
  routingStore: new RouterStore(),
  searchStore: new SearchStore(),
  ratingTopStore: new RatingTopStore(),
  ratingTagsStore: new RatingTagsStore(),
  ratingTopCommunityStore: new RatingTopCommunityStore(),
  profileStore: new ProfileStore(),
  accountsStore: new AccountsStore()
}

export default stores
