import React, { Component, PureComponent } from 'react'
import { Stores } from '../stores/RootStore'
import ObjectUtil from './ObjectUtil'
import qs from './QSUtil'
import { inject, observer } from 'mobx-react'
import { RouterStore } from 'mobx-react-router'
import { RouteComponentProps, withRouter } from 'react-router'

interface IWrapperProps extends RouteComponentProps {
  routing?: RouterStore
}

interface IParams<T> {
  setDefaultParams: (defaultParams?: T) => void
  changeParams: (replaceParams?: T) => void
  changeUrl: (url: string, params?: T) => void
}

export type ParamsProps<T = {}> = { [P in keyof T]: T[P] } & IParams<T> & IWrapperProps

let prevPathname = ''

/**
 * Компонент высшего уровня для парсинга location в params
 */
function withParams (Component: any): any {
  @inject(Stores.ROUTING)
  @observer
  class Wrapper extends PureComponent<IWrapperProps> {
    componentDidUpdate (prevProps: Readonly<IWrapperProps>, prevState: Readonly<{}>, snapshot?: any): void {
      console.log('Route changed', location)
      if (prevPathname !== this.props.history.location.pathname) {
        prevPathname = this.props.history.location.pathname
        window.scrollTo(0, 0)
      }
    }

    render (): JSX.Element {
      const params = {
        ...this.props.match.params,
        ...qs.parse(this.props.location?.search),
        setDefaultParams: (defaultParams: object): void => {
          const matchParams = {
            ...this.props.match.params,
            ...qs.parse(this.props.location?.search)
          }
          Object.keys(defaultParams).map(key => matchParams[key] = matchParams[key] || defaultParams[key])
          this.props.routing.push(qs.stringify(this.props.match.path, matchParams))
        },
        changeParams: (replaceParams: object): void => {
          let newParams = ObjectUtil.removeUndefined({
            ...this.props.match.params,
            ...qs.parse(this.props.location?.search),
            ...replaceParams
          })

          Object.keys(newParams).forEach(key => {
            if (newParams[key] === undefined || Array.isArray(newParams[key]) && !newParams[key].length) newParams[key] = null
          })

          this.props.routing.push(qs.stringify(this.props.match.path, newParams))
        },
        changeUrl: (url: string, params?: object): void => {
          if (this.props.routing) {
            this.props.routing.push(qs.stringify(url, { ...params }))
          }
        }
      }

      return (
        <Component
          {...this.props}
          params={params}
        />
      )
    }
  }

  return withRouter(Wrapper)
}

export default withParams
