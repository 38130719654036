import React, { Component, ReactNode } from 'react'

import './NoData.scss'

interface IProps {
  /**
   * Сообщение, которое будем выводить
   */
  children?: ReactNode
}

/**
 * Элемент NoData
 * Заглушка на случай, если нету даных.
 */
class NoData extends Component<IProps> {

  static defaultProps = {
    message: 'Нет данных'
  }

  render (): JSX.Element {

    const { children } = this.props

    return (
      <div className='no-data'>
        <span className='no-data__text'>{children}</span>
      </div>
    )
  }
}

export default NoData
