import * as Highcharts from 'highcharts'
import React, { Component } from 'react'

import ArrayUtil from '../../../../../utils/ArrayUtil'
import Chart from '../../../../modules/Chart/Chart'
import ReactDOMServer from 'react-dom/server'
import LineChartTooltip from './LineChartTooltip'

import './LineWidgetChart.scss'
import Loading from '../../../../elements/Loading/Loading'
import NumeralUtil from '../../../../../utils/NumeralUtil'

export interface ILineWidgetItem {
  date: number
  value: number
}

interface IProps {
  /**
   * Данные
   */
  data?: Array<ILineWidgetItem>
  /**
   * Цвет графика
   */
  color?: string
  /**
   * Состояние загрузки
   */
  loading?: boolean
  /**
   * Высота графика
   */
  height?: number
}

class LineWidgetChart extends Component<IProps> {
  render (): JSX.Element {
    let { data, color, loading, height } = this.props

    data = ArrayUtil.arrayObjectsSort('date', data)

    const series: Array<Highcharts.SeriesOptionsType> = [{
      type: 'areaspline',
      name: 'Название графика',
      data: data.map(item => ({
        name: '',
        y: item.value,
        x: item.date
      }))
    }]

    return (
      <div className='line-widget-chart'>
        {loading &&
          <Loading size={height}>Строим график</Loading>
        }
        {!loading && <Chart
          noData={!data.length}
          series={series}
          color={color}
          height={height}
          tooltip={function (): string {
            return ReactDOMServer.renderToString(
              <LineChartTooltip
                metricValue={NumeralUtil.format(this?.points[0].point.y, '0,0')}
                metricName='Подписчиков'
                date={this?.points[0].point.x}
              />
            )
          }}
        />}
      </div>
    )
  }
}

export default LineWidgetChart
