import { CancelTokenSource } from 'axios'
import { action, makeAutoObservable } from 'mobx'
import APIClient from '../lib/APIClient'
import whenChangedParameters from '../utils/whenChangedParameters'
import BaseAPI from './API/BaseAPI'
import { ITopCommunity } from './RatingTopStore'
import RootStore from './RootStore'

export interface ITag {
  tagID: string
  name: string
  icon: string
  parent: string
  count: number
}

export default class RatingTagsStore {
  public isLoading: boolean = false
  public community: ITopCommunity = null
  public tasksCount: number = 0
  public tags: Array<ITag> = []
  private _cancelSource: CancelTokenSource = null

  constructor () {
    makeAutoObservable(this)
  }

  @action
  @whenChangedParameters
  public async load (socialType: string, tags: Array<string>): Promise<void> {
    this.isLoading = true
    if (this._cancelSource) this._cancelSource.cancel()
    this._cancelSource = BaseAPI.cancelSource()

    try {
      const data = await APIClient.get('rating/tags', {
        socialType,
        tags: (tags || []).join(',')
      }, this._cancelSource.token)

      const list = data.data.data

      this.tags = list

      console.log('RatingTagsStore communities', list)

      this.isLoading = false
    } catch (e) {
      if (e.__CANCEL__) return

      console.log('RatingTagsStore', e.response)
      // validationErrors: e.response.data.meta.errors
      return
    }
  }

  public async loadCommunityTagsToApprove (cid: string): Promise<void> {
    this.isLoading = true
    if (this._cancelSource) this._cancelSource.cancel()
    this._cancelSource = BaseAPI.cancelSource()

    try {
      const data = await APIClient.get('rating/tags/approve', { cid }, this._cancelSource.token)
      this.community = data.data.data

      console.log('RatingTagsStore community', this.community)

      this.isLoading = false
    } catch (e) {
      if (e.__CANCEL__) return

      console.log('RatingTagsStore', e.response)
      // validationErrors: e.response.data.meta.errors
      return
    }
  }

  public async loadTagsApproveStatistic (): Promise<void> {
    this.isLoading = true
    if (this._cancelSource) this._cancelSource.cancel()
    this._cancelSource = BaseAPI.cancelSource()

    try {
      const data = await APIClient.get('secure/queue/statistics/json',
        { secure_token: '9c3de5e4e924f3187ed609559257b848' },
        this._cancelSource.token
      )
      this.tasksCount = data.data.data.find(item => item.queue === 'tagsApprove').totalTasks
      this.isLoading = false
    } catch (e) {
      if (e.__CANCEL__) return

      console.log('RatingTagsStore', e.response)
      // validationErrors: e.response.data.meta.errors
      return
    }
  }

  public async suggestTags (cid: string, tags: Array<string>): Promise<void> {
    if (this._cancelSource) this._cancelSource.cancel()
    this._cancelSource = BaseAPI.cancelSource()

    try {
      await APIClient.post('rating/tags/approve', { cid, tags }, this._cancelSource.token)
    } catch (e) {
      if (e.__CANCEL__) return

      console.log('RatingTagsStore', e.response)
      // validationErrors: e.response.data.meta.errors
      return
    }
  }

  public async approveTags (cid: string, tags: Array<string>): Promise<boolean> {
    if (this._cancelSource) this._cancelSource.cancel()
    this._cancelSource = BaseAPI.cancelSource()
    try {
      const result = await APIClient.patch('rating/tags/approve', { token: RootStore.profileStore.token, cid, tags }, this._cancelSource.token)
      return result.data.meta.code === 200
    } catch (e) {
      if (e.__CANCEL__) return false

      console.log('RatingTagsStore', e.response)
      // validationErrors: e.response.data.meta.errors
      return false
    }
  }

  getName (tagID: string): string {
    const tag = this.tags.find(tag => tag.tagID === tagID)
    if (tag) {
      const icon = tag.icon ? (tag.icon + ' ') : ''
      return icon + tag.name
    }

    return ''
  }

  getFullName (tagID: string, topLevel = true): string {
    const tag = this.tags.find(tag => tag.tagID === tagID)
    if (tag) {
      const icon = tag.icon ? (tag.icon + ' ') : ''

      const parent = this.getFullName(tag.parent, false)

      return (parent ? `${parent} - ` : '') + icon + tag.name + (topLevel ? ` (${tag.count})` : '')
    }

    return ''
  }
}
