import validator from 'validator'
import urlParse from 'url-parse'

/**
 * Класс, который позволяет удобно обрабатывать ссылки из соцсетей.
 *
 * Позволяет выделять из ссылки такую информацию, как:
 *  - Тип социальной сети (vk, fb, inst, ok, tw, tt, tg, yz, yt, pi)
 *  - Имя сообщества, содержащееся в ссылке
 *  - Ссылка на сообщество без лишних параметров
 */
class SocialUrlUtil {
  // Соцсеть, в которой находится данное сообщество
  private socialType: string = null
  // Имя сообщества, которое находится в ссылке на сообщество
  private screenName: string = null
  // Оригинальная ссылка на сообщество без лишних параметров
  private communityLink: string = null
  private rawURL: string = null

  /**
   * Конструктор, принимает строку, содержащую ссылку на сообщество
   *
   * @param {string} input
   */
  constructor (input) {
    this.rawURL = this._prepareInput(input)
    this._processSocialType()
  }

  /**
   * @return {string} ссылка на сообщество без лишних параметров
   */
  getCommunityLink = () => {
    if (this.communityLink === null) {
      throw new Error(`WrongURLException:: ${this.rawURL}`)
    }

    return this.communityLink
  }

  /**
   * @return {string} название сообщества, содержащееся в ссылке
   */
  getScreenName = () => {
    if (this.screenName === null) {
      throw new Error(`WrongURLException:: ${this.rawURL}`)
    }

    return this.screenName
  }

  /**
   * @return {string} тип соцсети
   */
  getSocialType = () => {
    if (this.socialType === null) {
      throw new Error(`WrongURLException:: ${this.rawURL}`)
    }

    return this.socialType
  }

  /**
   * Нормализация ГКД
   *
   * @private
   * @param input {string}
   * @return {string}
   */
  _prepareInput = (input) => {
    input = input.trim()
    if (!input.startsWith('http:') && !input.startsWith('https:')) {
      input = 'https://'.concat(input)
    }
    return input
  }

  /**
   * Удаляем первый символ, если это слеш ('/')
   *
   * @private
   * @param str {string}
   * @return {string}
   */
  _removeFirstCharIfItSlash = (str) => {
    return str.charAt(0) === '/'
      ? str.substring(1)
      : str
  }

  /**
   * Удаляем все символы после первого слеша (/')
   *
   * @private
   * @param str {string}
   * @return {string}
   */
  _removeAllAfterFirstSlash = (str) => {
    const index = str.indexOf('/')
    return index !== -1
      ? str.substring(0, index)
      : str
  }

  /**
   * Удаляем первый символ @ (At)
   *
   * @private
   * @param str {string}
   * @return {string}
   */
  _removeFirstCharIfItAt = (str) => {
    return str.charAt(0) === '@'
      ? str.substring(1)
      : str
  }

  /**
   * Обработка и парсинг ссылки в конкретной соцсети.
   *
   * @private
   */
  _processSocialType = () => {
    let url
    try {
      url = urlParse(this.rawURL)
    } catch (error) {
      if (error.code === 'ERR_INVALID_URL') {
        return
      }
    }

    // VK
    if (url.hostname.indexOf('vkontakte.ru') !== -1 || url.hostname.indexOf('vk.com') !== -1) {
      let screenName = url.pathname.toLowerCase()

      screenName = this._removeFirstCharIfItSlash(screenName)

      // Выделение groupId из url
      for (const startStr of ['public', 'event', 'albums-', 'videos-', 'audios-', 'board']) {
        const int = screenName.replace(startStr, '')
        if (screenName === startStr.concat(int) && validator.isInt(int)) {
          screenName = `club${int}`
          break
        }
      }

      this.screenName = screenName
      this.communityLink = url.origin.concat('/').concat(screenName)
      this.socialType = 'VK'

      return
    }

    // FB
    if (url.hostname.indexOf('facebook.com') !== -1) {
      // Если есть признак группы - ссылка не действительная
      if (url.href.indexOf('facebook.com/groups/') !== -1) {
        return
      }

      let screenName = url.pathname.toLowerCase().replace('/pg', '')
      screenName = this._removeFirstCharIfItSlash(screenName)
      screenName = this._removeAllAfterFirstSlash(screenName)

      const communityLink = url.origin.concat('/').concat(screenName)

      let intScreenName = screenName
      if (intScreenName.indexOf('-') !== -1) intScreenName = intScreenName.substring(screenName.lastIndexOf('-') + 1)
      if (validator.isInt(intScreenName) && intScreenName.length > 4) screenName = intScreenName

      this.screenName = screenName
      this.communityLink = communityLink
      this.socialType = 'FB'

      return
    }

    // TW
    if (url.hostname.indexOf('twitter.com') !== -1) {
      let screenName = url.pathname.toLowerCase()
      screenName = this._removeFirstCharIfItSlash(screenName)
      screenName = this._removeAllAfterFirstSlash(screenName)

      this.screenName = screenName
      this.communityLink = url.origin.concat('/').concat(screenName)
      this.socialType = 'TW'

      return
    }

    // INST
    if (url.hostname.indexOf('instagram.com') !== -1 || url.hostname.indexOf('instagr.am') !== -1) {
      let screenName = url.pathname.toLowerCase()
      screenName = this._removeFirstCharIfItSlash(screenName)
      screenName = this._removeAllAfterFirstSlash(screenName)

      this.screenName = screenName
      this.communityLink = url.origin.concat('/').concat(screenName)
      this.socialType = 'INST'

      return
    }

    // OK
    if (url.hostname.indexOf('odnoklassniki.ru') !== -1 || (url.hostname.indexOf('ok.ru') !== -1 && url.hostname.indexOf('facebook.com') === -1)) {
      let screenName = url.pathname.toLowerCase()

      screenName = this._removeFirstCharIfItSlash(screenName)

      // Выделение groupId из url
      const startStr = 'group/'
      let communityLink = null
      const int = this._removeAllAfterFirstSlash(screenName.replace(startStr, ''))
      if (validator.isInt(int)) {
        screenName = int
        communityLink = startStr.concat(int)
      } else {
        screenName = this._removeAllAfterFirstSlash(screenName)
      }

      this.screenName = screenName
      this.communityLink = url.origin.concat('/').concat(communityLink || screenName)
      this.socialType = 'OK'

      return
    }

    // YT
    if (url.hostname.indexOf('youtube.com') !== -1) {
      let screenName = url.pathname
        .replace('/c/', '/')
        .replace('/user', '')

      screenName = this._removeFirstCharIfItSlash(screenName)

      // console.log('screenName', screenName)

      // Если имя начинается с channel, то не можем удалять эту часть из ссылки
      let communityLink = null
      const startStr = 'channel/'
      if (screenName.startsWith(startStr)) {
        screenName = screenName.replace(startStr, '')
        screenName = this._removeAllAfterFirstSlash(screenName)

        communityLink = startStr.concat(screenName)
      }

      screenName = this._removeAllAfterFirstSlash(screenName)

      this.screenName = screenName
      this.communityLink = url.origin.concat('/').concat(communityLink || screenName)
      this.socialType = 'YT'

      return
    }

    // PI
    if (url.hostname.match(/(www.)?pinterest\./)) {
      let screenName = url.pathname.toLowerCase()

      screenName = this._removeFirstCharIfItSlash(screenName)
      screenName = this._removeAllAfterFirstSlash(screenName)

      this.screenName = screenName
      this.communityLink = url.origin.concat('/').concat(screenName)
      this.socialType = 'PI'

      return
    }

    // TG
    if (url.hostname.indexOf('t.me') !== -1 || url.hostname.indexOf('telegram.me') !== -1) {
      let screenName = url.pathname.toLowerCase()

      screenName = this._removeFirstCharIfItSlash(screenName)
      screenName = this._removeAllAfterFirstSlash(screenName)

      this.screenName = screenName
      this.communityLink = url.origin.concat('/').concat(screenName)
      this.socialType = 'TG'

      return
    }

    // TT
    if (url.hostname.indexOf('tiktok.com') !== -1) {
      let screenName = url.pathname.toLowerCase()

      screenName = this._removeFirstCharIfItSlash(screenName)
      screenName = this._removeAllAfterFirstSlash(screenName)
      screenName = this._removeFirstCharIfItAt(screenName)
      this.screenName = screenName
      this.communityLink = url.origin.concat('/@').concat(screenName)
      this.socialType = 'TT'

      return
    }

    // YZ
    if (url.hostname.indexOf('zen.yandex.ru') !== -1) {
      let screenName = url.pathname.toLowerCase()

      screenName = this._removeFirstCharIfItSlash(screenName)
      if (screenName.indexOf('id/') === -1) {
        screenName = this._removeAllAfterFirstSlash(screenName)
      }

      this.screenName = screenName
      this.communityLink = url.origin.concat('/').concat(screenName)
      this.socialType = 'YZ'

      return
    }

    // CH
    if (url.hostname.indexOf('www.joinclubhouse.com') !== -1) {
      let screenName = url.pathname.toLowerCase()

      screenName = this._removeFirstCharIfItSlash(screenName)

      this.screenName = screenName
      this.communityLink = url.origin.concat('/').concat(screenName)
      this.socialType = 'CH'

      return
    }

    // RT
    if (url.hostname.indexOf('rutube.ru') !== -1) {
      let screenName = url.pathname.toLowerCase()
      screenName = this._removeFirstCharIfItSlash(screenName)

      let communityLink = ''
      const types = ['channel/', 'metainfo/tv/']
      types.forEach(startStr => {
        if (screenName.startsWith(startStr)) {
          screenName = screenName.replace(startStr, '')
          screenName = this._removeAllAfterFirstSlash(screenName)
          communityLink = url.origin.concat(`/${startStr}`).concat(screenName)
          if (startStr.includes('tv')) screenName = 'tv' + screenName
        }
      })

      this.screenName = screenName
      this.communityLink = communityLink
      this.socialType = 'RT'

      return
    }

    console.log('Social type is not defined')
  }

  /**
   * Метод для получения ссылок на все соцсети по части названия
   *
   * @param screenName {string}
   */

  /**
   * Метод для получения ссылок на все соцсети по части названия
   *
   * @private
   * @param screenName {string}
   * @return {array of string}
   */
  _getUrlsByScreenName = (screenName) => {
    const urls = []

    screenName = screenName.trim()
    const screenNameToLower = screenName.toLocaleLowerCase()

    if (screenNameToLower.startsWith('http:') || screenNameToLower.startsWith('https:')) return [screenName]

    screenName = this._removeFirstCharIfItAt(screenName)

    urls.push(`https://vk.com/${screenName}`)
    urls.push(`https://ok.ru/${screenName}`)
    urls.push(`https://www.facebook.com/${screenName}`)
    urls.push(`https://twitter.com/${screenName}`)
    urls.push(`https://www.instagram.com/${screenName}`)
    urls.push(`https://t.me/${screenName}`)
    urls.push(`https://www.youtube.com/c/${screenName}`)
    urls.push(`https://www.tiktok.com/@${screenName}`)
    urls.push(`https://zen.yandex.ru/${screenName}`)

    return urls
  }
}

export default SocialUrlUtil
