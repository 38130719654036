import fsort from 'fast-sort'

/**
 * Хелпер массивов
 */
export default class ArrayUtil {
  /**
   * Функция сравнивает 2 массива (старые данные и новые данные) и возвращает объект с массивами элементов:
   * toAdd: массив с новыми элементами
   * toDelete: массив с элементами для удаления
   */
  public static arrayDiffResult (prevArray = [], newArray = []): object {
    let toAdd = []
    let toDelete = prevArray

    newArray.forEach(element => {
      if (prevArray.indexOf(element) === -1) {
        toAdd.push(element)
      } else {
        toDelete.splice(prevArray.indexOf(element), 1)
      }
    })

    return {
      toAdd,
      toDelete
    }
  }

  /**
   * Метод объединения массивов
   *
   * @param array1
   * @param array2
   * @returns {Array<object>}
   */
  public static arrayMerge (array1, array2): Array<object> {
    const newArray = [...array1]

    array2.forEach(item => {
      if (newArray.indexOf(item) === -1) {
        newArray.push(item)
      }
    })

    return newArray
  }

  /**
   * Сортировка массива объектов по свойству объекта
   * field2 - опция для сортировки по 2м значениям. для случаев с пагинацией, когда каждый раз сортировка меняется, если числа равны по field
   */
  public static arrayObjectsSort<T> (field: string, array: Array<T>, field2 = null): Array<T> {
    if (!field) return array
    let leftDirection = 1
    let rightDirection = -1

    // Обратная сортировка -field
    if (field.match(/^-/)) {
      leftDirection = -1
      rightDirection = 1
      field = field.replace(/^-/, '')
    }

    let sortBy = []
    if (leftDirection === -1) sortBy.push({ desc: i => i[field] === undefined ? null : i[field] })
    else sortBy.push({ asc: i => i[field] })
    if (field2) {
      if (leftDirection === -1) sortBy.push({ desc: i => i[field] === undefined ? null : i[field] })
      else sortBy.push({ asc: i => i[field] })
    }

    return fsort(array).by(sortBy)
  }

  /**
   * Сокращение данных до первых Х и Другое
   *
   * @param array
   * @param count
   */
  public static arrayTopCategoryCount<T> (array: Array<T>, count: number = 9): Array<T> {
    if (array.length <= 9) return array

    const topData = [
      ...array.slice(0, count),
      array.slice(count).reduce(
        (more, item) => ({
          ...more,
          count: more.count + item['count'],
          data: ArrayUtil.arrayMergeSum(item['data'], more.data)
        }),
        {
          category: 'Others',
          count: 0,
          data: []
        })
    ]

    return topData as Array<T>
  }

  public static arrayMergeSum<T> (array1: Array < T > , array2: Array<T>): Array < T > {
    if (!array1 .length) array1 = []
    if (!array2.length) return array1

    return array1.map((item, index) => {
      let newItem = {}

      Object.keys(item).forEach(key => {
        if (Number(item[key]) === item[key]) newItem[key] = (item[key] || 0) + (array2[index] && array2[index][key] || 0)
        else newItem[key] = item[key]
      })

      return newItem as T
    }) as Array<T>
  }
}
