import React, { Component, ReactNode } from 'react'
import cx from 'classnames'

import './PageLayout.scss'

export type PageLayoutIcon = 'login' | 'none' | 'attention' | 'user'

interface IProps {
  header?: ReactNode
  footer?: ReactNode
  children: ReactNode
  center?: boolean
  full?: boolean
  icon?: string
}

/**
 * Вид PageLayout - обёртка для страницы
 */
class PageLayout extends Component<IProps> {
  render (): JSX.Element {
    const { header, footer, children, center, full, icon } = this.props

    const classes = cx('page-layout', {
      'page-layout--center': center,
      [`page-layout--icon-${icon}`]: icon,
      'page-layout--full': full
    })

    return (
      <div className={classes}>
        {header}
        <div className='page-layout__container'>
          {children}
        </div>
        {footer}
      </div>
    )
  }
}

export default PageLayout
