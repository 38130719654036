import React, {Component, ReactNode} from 'react'
import { inject, observer } from 'mobx-react'

import withParams, { ParamsProps } from '../../utils/withParams'
import ProfileStore from '../../stores/ProfileStore'
import { Stores } from '../../stores/RootStore'
import Header from '../../ui/elements/Header/Header'
import HeaderLogo from '../../ui/elements/Header/HeaderLogo'
import HeaderMenu from '../../ui/elements/Header/HeaderMenu'
import HeaderMenuLink from '../../ui/elements/Header/HeaderMenuLink'
import HeaderAccount from '../../ui/elements/Header/HeaderAccount'
import HeaderAccountBlock from '../../ui/elements/Header/HeaderAccountBlock'
import Popup from '../../ui/elements/Popup/Popup'
import PopupButton from '../../ui/elements/Popup/PopupButton'
import PopupDivider from '../../ui/elements/Popup/PopupDivider'
import AccountsStore from '../../stores/AccountsStore'
import ButtonText from '../../ui/elements/ButtonText/ButtonText'

interface IProps {
  params?: ParamsProps
  subHeader?: ReactNode
  profileStore?: ProfileStore
  accountsStore?: AccountsStore
}

@withParams
@inject(Stores.PROFILE_STORE, Stores.ACCOUNTS_STORE)
@observer

class HeaderBlock extends Component<IProps> {
  handleLogout = () => {
    this.props.params.changeUrl('/')
    this.props.profileStore.logout()
  }

  render (): JSX.Element {
    const { subHeader, profileStore } = this.props

    if (!profileStore.isAuth) {
      return (
        <Header subHeader={subHeader}>

          <HeaderLogo />

          <HeaderMenu>
            <HeaderMenuLink to='/rating'>Рейтинг страниц</HeaderMenuLink>
            <HeaderMenuLink to='https://suite.jagajam.com/'>JagaJam Suite</HeaderMenuLink>
            <HeaderMenuLink to='https://new.jagajam.com/ru/?modal=login'>JagaJam Analytics</HeaderMenuLink>
          </HeaderMenu>

          <HeaderAccountBlock>
            <Popup
              trigger={<ButtonText size='small'>Войти в сервис</ButtonText>}
              size='small'
            >
              <PopupButton to='https://suite.jagajam.com/'>Suite</PopupButton>
              <PopupButton to='https://new.jagajam.com/ru/?modal=login'>Analytics</PopupButton>
            </Popup>
          </HeaderAccountBlock>

        </Header>)
    }

    return (
      <Header subHeader={subHeader}>

        <HeaderLogo />

        <HeaderMenu>
          <HeaderMenuLink to='/rating'>Рейтинг страниц</HeaderMenuLink>
          <HeaderMenuLink to='https://suite.jagajam.com/'>JagaJam Suite</HeaderMenuLink>
          <HeaderMenuLink to='https://new.jagajam.com/ru/?modal=login'>JagaJam Analytics</HeaderMenuLink>
        </HeaderMenu>

        <HeaderAccountBlock>
          <Popup
            trigger={(
              <HeaderAccount
                image={profileStore?.profile?.image}
                name={profileStore?.profile?.name}
              />)}
            maxHeight={700}
          >
            <PopupButton to='/approval'>Разметка тегов</PopupButton>
            <PopupDivider />
            <PopupButton icon='logout_circle' onClick={() => this.handleLogout()}>Выйти из Rating</PopupButton>
          </Popup>
        </HeaderAccountBlock>

      </Header>
    )
  }
}

export default HeaderBlock
