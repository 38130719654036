import React, { Component } from 'react'

import './RatingInfo.scss'

import Image from '../Image/Image'
import Icon from '../Icon/Icon'
import SocialUrlUtil from '../../../utils/SocialUrlUtil'
import NumeralUtil from '../../../utils/NumeralUtil'
import Tag from '../Tag/Tag'
import TagGroup from '../Tag/TagGroup'

interface IProps {
  image: string
  name: string
  url: string
  usersCount: number
  socials: Map<string, string>
  description: string
  webSite?: string
  socialCategories?: string
  fullDescription?: boolean
  tags: Array<{ tagID: string, name: string, position?: number }>
  position?: number
  onSelectTag?: (tagID: string) => void
}

/**
 * Элемент RatingInfo.
 * Информация по выбранному элементу рейтинга
 */

class RatingInfo extends Component<IProps> {
  render (): JSX.Element {
    const { name, image, url, usersCount, position, tags, socials, description, fullDescription, webSite, socialCategories, onSelectTag } = this.props

    const socialURL = new SocialUrlUtil(url)
    const socialType = socialURL.getSocialType()
    const screenName = socialURL.getScreenName()

    return (
      <div className='rating-info'>
        <Image className='rating-info__image' border round src={image} noImage={require('./img/no_avatar.svg')} />

        <div className='rating-info__container'>
          <div className='rating-info__badges'>
            <TagGroup middle>
            {tags.map(tag => (
              <Tag
                key={tag.tagID}
                size='middle'
                onClick={() => onSelectTag(tag.tagID)}
              >
                { tag.name }
              </Tag>
            ))}
            </TagGroup>
          </div>

          <div className='rating-info__content'>

            <div className='rating-info__container'>
              <span className='rating-info__name'>{name}</span>

              <div className='rating-info__social'>
                <div className='rating-info__social-block'>
                  <Icon className='rating-info__social-icon' icon={`${socialType.toLowerCase()}_colored`}/>
                  {socialType === 'CH' && <span className='rating-info__social-label'>{screenName}</span>}
                  {socialType !== 'CH' && <a className='rating-info__social-link' href={url} target='_blank'>{screenName}</a>}
                </div>
                {socials && socials['INST'] && (
                  <div className='rating-info__social-block'>
                    <Icon className='rating-info__social-icon' icon='ig_colored'/>
                    <a className='rating-info__social-link' href={`https://instagram.com/${socials['INST']}`} target='_blank'>{socials['INST']}</a>
                  </div>
                )}
                {socials && socials['TW'] && (
                  <div className='rating-info__social-block'>
                    <Icon className='rating-info__social-icon' icon='tw_colored'/>
                    <a className='rating-info__social-link' href={`https://twitter.com/${socials['TW']}`} target='_blank'>{socials['TW']}</a>
                  </div>
                )}
              </div>

              <span className='rating-info__subscribers'>{NumeralUtil.format(usersCount, '0,0', ['подписчик', 'подписчика', 'подписчиков'])}</span>
              <pre className={`rating-info__bio ${fullDescription ? 'rating-info__bio--full' : ''}`}>{description}</pre>
              {webSite && <a href={webSite} target='_blank' className='rating-info__site'>{webSite}</a>}
              {socialCategories && <span className='rating-info__categories'>{socialCategories}</span>}
            </div>

            {position && <>
              {position <= 1000 &&
                <div className='rating-info__place'>
                  <div className='rating-info__place-container'>
                    <Icon className='rating-info__place-icon' icon='admin'/>
                    <span className='rating-info__value'>{position}</span>
                  </div>
                  {tags.map(tag => (
                    <span className='rating-info__sub-value' key={tag.tagID}>#{tag.position} в {tag.name}</span>
                  ))}
                </div>
              }
              {position > 1000 &&
                <div className='rating-info__place'>
                  <div className='rating-info__place-container'>
                    <Image className='rating-info__place-image' src={require('./img/no_rating.png')}/>
                  </div>
                  <div className='rating-info__container'>
                    <span className='rating-info__sub-value'>К сожалению этот аккаунт</span>
                    <span className='rating-info__sub-value'>не входит в 1000 лучших</span>
                    <span className='rating-info__sub-value'>в общем рейтинге</span>
                    <br/>
                    {tags.map(tag => (
                      <span className='rating-info__sub-value' key={tag.tagID}>#{tag.position} в {tag.name}</span>
                    ))}
                  </div>
                </div>
              }
            </>}
          </div>

        </div>

      </div>
    )
  }
}

export default RatingInfo
