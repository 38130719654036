import { action } from 'mobx'

interface MobxFormItem<T> {
  value: T
  error: string

  change: (value: T) => void

}

export type MobxForm<T> = Partial<{
  [P in keyof T]: MobxFormItem<T[P]>
} & {
  setErrors: (errors: Partial<T>) => void
  setData: (data: Partial<T>) => void
  getData: () => Partial<T>
} & T>

export function createForm<T extends {}> (form: MobxForm<T>, fields: T): void {
  // console.log('form', form)
  // console.log('fields', fields)
  Object.keys(fields).forEach(prop => {
    form[prop] = {
      value: fields[prop],
      error: '',
      change: action((value: T): void => {
        // console.log('change', form, prop)
        if (form[prop]) form[prop].value = value
      })
    }

    // @ts-ignore
    form.setErrors = action((errors: Partial<T>): void => {
      Object.keys(form).forEach(prop => {
        if (form[prop].error) form[prop].error = ''
      })

      Object.keys(errors).forEach(prop => {
        if (form[prop]) form[prop].error = errors[prop]
      })
    })

    // @ts-ignore
    form.setData = action((data: Partial<T>): void => {
      Object.keys(data).forEach(prop => {
        if (form[prop]) {
          form[prop].value = data[prop]
          form[prop].error = ''
        }
      })
    })

    // @ts-ignore
    form.getData = action((): Partial<T> => {
      const data = {}
      Object.keys(form).forEach(prop => {
        if (form[prop].value) data[prop] = form[prop].value
      })

      return data
    })
    // console.log(prop)
  })
}
