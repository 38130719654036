import React, { Component } from 'react'
import { Redirect, Switch, RouteComponentProps } from 'react-router'
import { YMInitializer } from 'react-yandex-metrika'
import { inject, observer } from 'mobx-react'

import withParams from '../utils/withParams'
import { Stores } from '../stores/RootStore'
import PrivateRoute from './layouts/PrivateRoute'
import PublicRoute from './layouts/PublicRoute'
import MainPage from './MainPage'
import NotFoundPage from './NotFoundPage'
import AccessDeniedPage from './AccessDeniedPage'
import ProfileStore from '../stores/ProfileStore'
import LoginPage from './LoginPage'
import ApprovalPage from './ApprovalPage'
import RatingPage from './rating/RatingPage'
import RatingDetailPage from './rating/RatingDetailPage'

interface IProps extends RouteComponentProps {
  profileStore?: ProfileStore
}

@withParams
@inject(Stores.PROFILE_STORE)
@observer
class App extends Component<IProps> {

  componentDidCatch (error, errorInfo): void {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    // alert(error)
    // document.writeln(`<pre>${error} ${errorInfo}</pre>`)
  }

  render (): JSX.Element {
    // console.log('App props', this.props)

    return (
      <>
        <Switch>
          <Redirect path='/' exact to='/rating' />
          <Redirect path='/rating' exact to='/rating/inst?tags[0]=russia' />
          <PublicRoute path='/rating/:social' exact component={RatingPage} />
          <PublicRoute path='/rating/:social/:screenName/:groupID' component={RatingDetailPage} />
          <PublicRoute path='/login' component={LoginPage} />
          <PrivateRoute path='/approval' component={ApprovalPage} />
          <PublicRoute path='/access-denied' component={AccessDeniedPage} />
          <PublicRoute path='/not-found' component={NotFoundPage} />
          <Redirect to='/not-found' />
        </Switch>
        <YMInitializer accounts={[37972780]} options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true, trackHash: true }} version='2' />
      </>
    )
  }
}

export default App
