import React, { ChangeEventHandler, Component, MouseEventHandler, ReactElement, RefObject } from 'react'
import cx from 'classnames'

import './InputText.scss'
import Icon from '../Icon/Icon'
import Tooltip from '../../modules/Tooltip/Tooltip'

type Type = 'text' | 'password'

interface IProps {
  /**
   * Аттрибут name
   */
  name?: string
  /**
   * Значение
   */
  value: string
  /**
   * Ошибка
   */
  error?: string
  /**
   * Является ли поле ввода не активным
   */
  disabled?: boolean
  /**
   * Обработчик onChange, вызывается при изменении значения
   */
  onChange?: ChangeEventHandler<HTMLInputElement>
  /**
   * Обработчик onFocus, вызывается при вызове фокуса
   */
  onFocus?: ChangeEventHandler<HTMLInputElement>
  /**
   * Обработчик onBlur, вызывается при потере фокуса
   */
  onBlur?: ChangeEventHandler<HTMLInputElement>
  /**
   * Подпись
   */
  label: string
  /**
   * Тип
   */
  type?: Type,
  /**
   * Фокус по-умолчанию при создании
   */
  focus?: boolean
  /**
   * Элементы
   */
  children?: ReactElement
  /**
   * Большой размер
   */
  big?: boolean
  /**
   * Размер для рейтинга
   */
  rating?: boolean
  /**
   * На белом фоне
   */
  onWhite?: boolean
}

/**
 * Элемент InputText
 * Поле для ввода текста
 */

class InputText extends Component<IProps> {
  private ref: RefObject<any>

  static defaultProps = {
    value: '',
    type: 'text'
  }

  state = {
    focus: this.props.focus && !this.props.disabled
  }

  constructor (props) {
    super(props)
    this.ref = React.createRef()

    this.focus()
  }

  componentDidUpdate (prevProps): void {
    this.focus()
  }

  focus = (e?) => {
    setTimeout(() => {
      if (this.state.focus && !this.props.disabled && this.ref.current) this.ref.current.focus()
      if (e && this.props.onFocus) this.props.onFocus(e)
    }, 0)
  }

  handleClick = (e) => {
    this.setState({ focus: true })
    this.focus(e)
  }

  handleFocus = (e) => {
    this.setState({ focus: true })
    this.focus(e)
  }

  handleBlur = (e) => {
    this.setState({ focus: false })
    if (this.props.onBlur) this.props.onBlur(e)
  }

  handleClear = (e) => {
    e.target.value = ''
    this.props.onChange(e)
  }

  render (): JSX.Element {
    const {
      name, type, value, error, disabled, label, onChange, children, big, rating, onWhite
    } = this.props
    const { focus } = this.state
    const filled = Boolean(String(value)?.length)

    const classes = cx('input-text__container', {
      'input-text--disabled': disabled,
      'input-text--focus': focus,
      'input-text--big': big,
      'input-text--rating': rating,
      'input-text--error': error,
      'input-text--filled': filled,
      'input-text--on-white': onWhite
    })

    return (
      <div className='input-text'>
        <div className={classes} onClick={!disabled && this.handleClick}>
          <span className='input-text__label'>{label}</span>
          <input
            className='input-text__text'
            placeholder=''
            id={name}
            name={name}
            value={String(value)}
            ref={this.ref}
            onChange={onChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            type={type}
            disabled={disabled}
            autoComplete='off'
          />
          <div className='input-text__icons'>
            {!big && !disabled && filled && <Icon className='input-text__icon input-text__icon-clear' icon='control_delete' onClick={this.handleClear} />}
            {!big && error && <Tooltip trigger={<Icon className='input-text__icon input-text__icon-error' icon='tariff_promise' />}>{error}</Tooltip>}
          </div>
        </div>
        {children}
      </div>
    )
  }
}

export default InputText
