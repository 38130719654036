import React, { Component } from 'react'
import NumeralUtil from '../../../utils/NumeralUtil'
import cx from 'classnames'

import './RatingMetric.scss'
import Icon from '../Icon/Icon'

interface IProps {
  name: string
  value: number
}

/**
 * Элемент RatingMetric.
 */

class RatingMetric extends Component<IProps> {
  render(): JSX.Element {

    const { name, value } = this.props

    const classes = cx('rating-metric', {
      'rating-metric--red': value < 0
    })

    return (
      <div className={classes}>
        <div className='rating-metric__block'>
          <Icon className='rating-metric__icon' icon={value < 0 ? 'stats_down' : 'stats_up'} />
          <span className='rating-metric__value'>{NumeralUtil.format(Math.abs(value), '0,0')}</span>
        </div>
        <span className='rating-metric__label'>{name}</span>
      </div>
    )
  }
}

export default RatingMetric
