import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Image from '../Image/Image'
import NumeralUtil from '../../../utils/NumeralUtil'

import './LookAlike.scss'
import Icon from '../Icon/Icon'

interface IProps {
  image: string
  name: string
  usersCount: number
  to: string
}

/**
 * Элемент LookAlike.
 * Похожие пользователи / сообщества
 */
class LookAlike extends Component<IProps> {

  render(): JSX.Element {
    const { image, name, usersCount, to } = this.props

    return (
      <Link to={to} className='look-alike'>
        <Image className='look-alike__image' border round src={image} noImage={require('./img/no_avatar.svg')}/>
        <span className='look-alike__label'>{name}</span>
        <div className='look-alike__metric'>
          <Icon className='look-alike__icon' icon='person'/>
          <span className='look-alike__value'>{NumeralUtil.format(usersCount, '0,0')}</span>
        </div>
      </Link>
    )
  }
}

export default LookAlike
