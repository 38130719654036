import React, { Component, ReactNode } from 'react'

import PageLayout, { PageLayoutIcon } from '../../ui/views/PageLayout/PageLayout'
import Footer from '../../ui/elements/Footer/Footer'
import HeaderBlock from './HeaderBlock'

interface IProps {
  /**
   * Компоненты, которые лежат внутри
   */
  children: ReactNode
  subHeader?: ReactNode
  center?: boolean
  full?: boolean
  icon?: PageLayoutIcon
}

class LiteLayout extends Component<IProps> {
  render (): JSX.Element {
    const { children, subHeader, center, full, icon } = this.props

    return (
      <PageLayout
        header={<HeaderBlock subHeader={subHeader} />}
        footer={<Footer/>}
        center={center}
        full={full}
        icon={icon}
      >
        {children}
      </PageLayout>
    )
  }
}

export default LiteLayout
