import React, { Component, MouseEventHandler } from 'react'
import cx from 'classnames'

import Image from '../Image/Image'
import Icon from '../Icon/Icon'
import CommunityUrl from '../../views/CommunityUrl/CommunityUrl'
import Button from '../Button/Button'

import './Community.scss'
import Checkbox from '../Checkbox/Checkbox'

type TStatus = 'blocked' | 'error' | 'process' | ''
type TIconStatus = 'active' | 'disabled' | 'error'

interface IProps {
  /**
   * Картинка
   */
  image: string
  /**
   * Название
   */
  name: string
  /**
   * Ссылка на сообщество
   */
  url: string
  /**
   * Количество участников
   */
  usersCount?: string
  /**
   * Администратор
   */
  admin?: boolean
  /**
   * Текст поля администратор
   */
  adminText?: string
  /**
   * Статус сообщества
   */
  status?: TStatus
  /**
   * Кнопка добавления
   */
  activeBtn?: string
  /**
   * Кнопка удаления
   */
  removeBtn?: boolean
  /**
   * Кнопка настроек
   */
  settingsBtn?: boolean
  /**
   * Иконка мессенджера
   */
  messengerIcon?: TIconStatus
  /**
   * Иконка постинга
   */
  postingIcon?: TIconStatus
  /**
   * Иконка общей статистики
   */
  statsIcon?: TIconStatus
  /**
   * Иконка охватов
   */
  reachIcon?: TIconStatus
  /**
   * Текст сообщения
   */
  noteText?: string
  /**
   * Иконка сообщения
   */
  noteIcon?: string
  /**
   * Цвет иконки сообщения
   */
  noteIconColor?: string
  /**
   * Обработчик кнопки удаления
   */
  onRemove?: MouseEventHandler
  /**
   * Обработчик кнопки редактирования
   */
  onSettings?: MouseEventHandler
  /**
   * Обработчик кнопки добавления
   */
  onActive?: MouseEventHandler
  /**
   * Сообщество можно выбрать (появляется чекбокс)
   */
  checked?: boolean
  /**
   * Обработчик клика
   */
  onClick?: MouseEventHandler
}

/**
 * Элемент Community
 * Сообщество
 */

export default class Community extends Component<IProps> {
  render (): JSX.Element {
    const {
      image, name, url, usersCount, admin, adminText, status,
      activeBtn, removeBtn, settingsBtn,
      messengerIcon, postingIcon, statsIcon, reachIcon,
      noteText, noteIcon, noteIconColor,
      onRemove, onSettings, onActive, checked, onClick
    } = this.props

    const icons = { blocked: 'c_error', error: 'undetected', process: 'analyze_static' }

    return (
      <div className='community' onClick={onClick}>
        <Image className='community__image' round border src={image}>
          {
            status &&
              <div className='community__image-background'>
                <Icon className='community__image-icon' icon={icons[status]} />
              </div>
          }
        </Image>
        <div className='community__main'>
          <div className='community__title'>
            {admin && adminText && <span className='community__isAdmin'>{adminText}</span>}
            <span className='community__name'>{name}</span>
          </div>
          <div className='community__description'>
            <CommunityUrl to={url} />
            {usersCount && <Icon className='community__usersCount-icon' icon='person' />}
            {usersCount && <span className='community__usersCount'>{usersCount}</span>}
          </div>
        </div>
        <div className='community__right'>
          {onActive && <Button onClick={onActive}>{activeBtn}</Button>}

          {!onActive && (noteText || (noteIcon && noteIconColor)) &&
            <div className='community__note-container'>
              <span className='community__note-text'>{noteText}</span>
              <Icon className='community__note-icon' icon={noteIcon} color={noteIconColor} />
            </div>}

          {!onActive && !noteText && !noteIcon &&
            <div className='community__controls'>
              {reachIcon && <Icon className={cx('community__control-icon', { [`community__control-icon--${reachIcon}`]: true })} icon='paid' />}
              {statsIcon && <Icon className={cx('community__control-icon', { [`community__control-icon--${statsIcon}`]: true })} icon='dashboard_domain' />}
              {postingIcon && <Icon className={cx('community__control-icon', { [`community__control-icon--${postingIcon}`]: true })} icon='page' />}
              {messengerIcon && <Icon className={cx('community__control-icon', { [`community__control-icon--${messengerIcon}`]: true })} icon='icon_comment' />}

              {((reachIcon || statsIcon || postingIcon || messengerIcon) && (settingsBtn || removeBtn)) && <div className='community__control-divider' />}
              {settingsBtn && <Icon onClick={onSettings} className='community__control-button' icon='gear' />}
              {removeBtn && <Icon onClick={onRemove} className='community__control-button community__control-button--attention' icon='trash' />}
            </div>}
          {checked !== undefined && <Checkbox checked={checked} />}
        </div>
      </div>)
  }
}
