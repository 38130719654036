import { CancelTokenSource } from 'axios'
import { action, makeAutoObservable } from 'mobx'
import APIClient from '../lib/APIClient'
import BaseAPI from './API/BaseAPI'
import ArrayUtil from '../utils/ArrayUtil'
import ICommunity from '../interfaces/ICommunity'

export default class SearchStore {
  public isLoading: boolean = false
  public communities: Array<ICommunity> = []
  private _cancelSource: CancelTokenSource = null

  constructor () {
    makeAutoObservable(this)
  }

  @action
  public async load (socialType: string, query: string): Promise<void> {
    this.isLoading = true
    this.communities = []
    if (this._cancelSource) this._cancelSource.cancel()
    this._cancelSource = BaseAPI.cancelSource()

    try {
      const data = await APIClient.get('search', {
        q: query,
        page: 1,
        perPage: 100,
        // socialTypes: [socialType]
      }, this._cancelSource.token)

      const communities = data.data.data

      this.communities =  data.data.data as Array<ICommunity>

      console.log('SearchStore communities', communities)

      this.isLoading = false
    } catch (e) {
      if (e.__CANCEL__) return

      console.log('SearchStore', e.response)
      // validationErrors: e.response.data.meta.errors
      return
    }
  }
}
