import React, { Component } from 'react'

import './LineChartTooltip.scss'
import DateUtil from '../../../../../utils/DateUtil'

interface IProps {
  metricName: string
  metricValue: string
  date: number
}

/**
 * Элемент ChartTooltip - всплывающая подсказка к графику
 */

class LineChartTooltip extends Component<IProps> {
  render (): JSX.Element {
    const { metricName, metricValue, date } = this.props

    return (
      <div className='chart-tooltip'>
        <div className='chart-tooltip__metric'>
          <span className='chart-tooltip__name'>{metricName}</span>
          <span className='chart-tooltip__value'>{metricValue}</span>
          <span className='chart-tooltip__date'>{DateUtil.format(date, 'L')}</span>
        </div>
      </div>
    )
  }
}

export default LineChartTooltip
