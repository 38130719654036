import React, { Component, ReactNode, RefObject } from 'react'
import ScrollArea from 'react-scrollbar'
import cx from 'classnames'
import { throttle } from 'lodash'
import { withResizeDetector } from 'react-resize-detector/build/withPolyfill'

import './ScrollView.scss'

interface IProps {
  children: ReactNode
  horizontal?: boolean
  padding?: boolean
  /**
   * Прокидывается только withResizeDetector
   */
  height: number
  className?: string
  /**
   * Максимальная высота, но не более чем контент
   */
  maxHeight?: number
  /**
   * Автоматическая прокрутка вконец списка
   */
  autoScrolling?: boolean
}

/**
 * Компонент ScrollView
 */
class ScrollView extends Component<IProps> {
  private ref: RefObject<any>

  static defaultProps = {
    horizontal: false,
    padding: false
  }

  state = {
    children: null,
    isFixing: true,
    contentHeight: 0
  }

  constructor (props) {
    super(props)
    this.ref = React.createRef()
  }

  componentDidUpdate (prevProps): void {
    if (prevProps.children !== this.props.children || prevProps.height !== this.props.height) {
      if (this.state.isFixing) {
        this.handleRefresh()
      }
    }
  }

  handleRefresh = () => {
    if (this.props.autoScrolling && this.state.isFixing) {
      setTimeout(() => {
        this.ref.current.scrollArea.scrollYTo(this.state.contentHeight + 1000)
        this.setState({ isFixing: true })
      }, 0)
    }
  }

  handleScroll = value => {
    const isFixing = Boolean(value.realHeight - value.topPosition <= value.containerHeight)
    const contentHeight = value.realHeight || this.state.contentHeight
    if (this.state.isFixing !== isFixing || this.state.contentHeight !== contentHeight) {
      this.setState({ isFixing, contentHeight })
    }
  }

  render (): JSX.Element {
    const { children, horizontal, height, className, maxHeight } = this.props

    if (this.ref.current && this.ref.current.focusContent) this.ref.current.focusContent = () => {}

    return (
      <ScrollArea
        onClick={this.handleRefresh}
        ref={this.ref}
        className={cx('scroll-view', className)}
        contentClassName={cx('scroll-view__content', { 'scroll-view__content--full-width': !horizontal })}
        horizontal={horizontal}
        stopScrollPropagation
        onScroll={throttle(this.handleScroll, 100)}
        style={{ height: maxHeight ? `${Math.min(maxHeight, this.state.contentHeight)}px` : 'auto' }}
        contentStyle={{ minHeight: this.props.padding ? height : 0 }}
      >
        {this.props.padding && <div className='scroll-view__padding' />}
        {children}
      </ScrollArea>
    )
  }
}

const wrappedScrollView = withResizeDetector(ScrollView)
wrappedScrollView.displayName = 'ScrollView'

export default wrappedScrollView
