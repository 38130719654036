import React, { Component } from 'react'

import './PopupDivider.scss'

/**
 * Элемент PopupDivider
 */
export default class PopupDivider extends Component {
  render (): JSX.Element {
    return (
      <div className='popup-divider' />)
  }
}
