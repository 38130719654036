import React, {Component, ReactNode} from 'react'
import cx from 'classnames'

import './TagGroup.scss'

interface IProps {
  children: ReactNode
  className?: string
  oneLine?: boolean
  middle?: boolean
}

/**
 * Элемент TagGroup
 */

class TagGroup extends Component<IProps> {
  render (): JSX.Element {
    const { oneLine, middle } = this.props

    const classes = cx('tag-group', this.props.className, {
      'tag-group--one-line': oneLine,
      'tag-group--middle': middle
    })

    return (
      <div className={classes}>
        {this.props.children}
      </div>
    )
  }
}

export default TagGroup
