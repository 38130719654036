import React, { Component, ComponentClass } from 'react'
import { Route } from 'react-router'
import { inject, observer } from 'mobx-react'
import ProfileStore from '../../stores/ProfileStore'
import { Stores } from '../../stores/RootStore'
import withParams, { ParamsProps } from '../../utils/withParams'

interface IPropsComponent {
  component: ComponentClass
  params?: ParamsProps
  profileStore?: ProfileStore
}

@withParams
@inject(Stores.PROFILE_STORE)
@observer
class PrivateComponent extends Component<IPropsComponent> {
  render (): JSX.Element {
    const { profileStore, component: Component } = this.props

    if (profileStore.isLoading) {
      return null
    }

    if (!profileStore.token) {
      this.props.params.changeUrl('/access-denied')
    }

    return <Component {...this.props} />
  }
}

interface IPropsRoute {
  /**
   * Путь
   */
  path: string,
  /**
   * Приватный доступ
   */
  private?: boolean
  /**
   * Точное совпадение пути
   */
  exact?: boolean,
  /**
   * Компонент
   */
  component: ComponentClass
}

/**
 * Приватный роут
 */
export default class PrivateRoute extends Component<IPropsRoute> {
  render (): JSX.Element {
    const { path, exact, component } = this.props

    return <Route path={path} exact={exact} render={() => <PrivateComponent component={component} />} />
  }
}
