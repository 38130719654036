import React, { Component, ReactNode } from 'react'

import './Loading.scss'
import Icon from '../Icon/Icon'

interface IProps {
  /**
   * Высота блока
   */
  size?: number
  /**
   *  Сообщение
   */
  children?: ReactNode
}

/**
 * Элемент Loading
 * Заглушка на время, пока загружаются данные.
 */
class Loading extends Component<IProps> {

  static defaultProps = {
    size: '50'
  }

  render (): JSX.Element {

    const { size, children } = this.props

    return (
      <div className='loading' style={{ height: `${size}px` }}>
        <Icon className='loading__icon' icon='loader' />
        <span className='loading__text'>{children}</span>
      </div>
    )
  }
}

export default Loading
