import React, { Component, ReactNode } from 'react'
import cx from 'classnames'

import './Header.scss'
import {throttle} from 'lodash'
import eventStack from '../../../lib/eventStack'

interface IProps {
  /**
   * Содержимое элемента
   */
  children: ReactNode
  subHeader?: ReactNode
}

/**
 * Элемент Header
 */
class Header extends Component<IProps> {
  private _throttleHandleUpdate: Function

  state = {
    isScrollTop: true
  }

  constructor (props) {
    super(props)
    this._throttleHandleUpdate = throttle(this.handleUpdate, 100)

    eventStack.sub('scroll', this._throttleHandleUpdate, 'Header')
  }

  componentWillUnmount (): void {
    eventStack.unsub('scroll', this._throttleHandleUpdate, 'Header')
  }

  handleUpdate = () => {
    const topScroll = document.body.scrollTop || document.documentElement.scrollTop

    if (this.state.isScrollTop !== !Boolean(topScroll)) {
      this.setState({ isScrollTop: !Boolean(topScroll) })
    }
  }

  render (): ReactNode {
    const { children, subHeader } = this.props

    const classes = cx('header', {
      'header--scroll': !this.state.isScrollTop
    })

    return (
      <>
        <header className={classes}>
          <div className='header__container'>
            {children}
          </div>
        </header>
        <div className='sub-header'>
          <div className='sub-header__bg'>
            <div className='sub-header__container'>
              {subHeader}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Header
