import React, { Component, MouseEventHandler, ReactNode } from 'react'

import cx from 'classnames'
import NumeralUtil, { NumeralFormat } from '../../../utils/NumeralUtil'
import Tooltip from '../../modules/Tooltip/Tooltip'
import Icon from '../Icon/Icon'

import './Metric.scss'
import Description from '../Description/Description'

interface IProps {
  /**
   * Размер блока
   */
  big?: boolean
  active?: boolean

  value: number | string
  deltaValue?: number
  deltaFormat?: NumeralFormat
  /**
   * Прирост отображать в процентах
   */
  deltaPercent?: boolean
  /**
   * Название метрики
   */
  title: string
  /**
   * Заголовок подсказки
   */
  tooltipTitle?: string
  /**
   * Текст подсказки
   */
  tooltipText?: ReactNode
  /**
   * Мелкий текст в подсказке
   */
  tooltipDescription?: ReactNode
  /**
   * Кнопка в подсказке
   */
  tooltipButton?: string
  /**
   * Кнопка закрытия в подсказке
   */
  tooltipClose?: boolean
  /**
   * Событие клика
   */
  onClick?: MouseEventHandler
  /**
   * Метркиа заблокирована
   */
  locked?: boolean
  /**
   * Страница для добавления
   */
  addUrl?: string
}

/**
 * Элемент Metric
 * Метрика
 */
class Metric extends Component<IProps> {

  getDeltaValue = (): string => {
    const { value, deltaValue, deltaPercent } = this.props
    if (!deltaValue) {
      return 'Нет изменений'
    }
    if (!deltaPercent && deltaValue) {
      return NumeralUtil.format(Math.abs(deltaValue), '0,0')
    }
    if (deltaPercent && deltaValue) {
      if (typeof value === 'number') {
        const valuePct = (value !== deltaValue) ? Math.abs(deltaValue / (value - deltaValue)) : 1
        if (valuePct < 0.01) {
          return NumeralUtil.format(Math.max(valuePct, 0.0001), '0.00%')
        }
        return NumeralUtil.format(valuePct, '0,0')
      } else {
        return deltaValue.toString()
      }
    }

    return ''
  }

  render (): JSX.Element {
    const { active, big, title, value, deltaValue, tooltipText,
      tooltipDescription, tooltipButton, tooltipTitle, tooltipClose, onClick, locked, addUrl } = this.props

    const classes = cx('metric', {
      'metric--big': big,
      'metric--active': active,
      'metric--locked': locked,
      'metric--red-delta-value': deltaValue < 0,
      'metric--grey-delta-value': !deltaValue || deltaValue === 0
    })

    return (
      <div className={classes} onClick={!big && onClick ? onClick : undefined}>
        {!locked && <>
        <div className='metric__top'>
          <span className='metric__title' onClick={big && onClick}>{title}</span>
          {tooltipText && <Tooltip
            trigger={<Icon className='metric__icon' icon='help' />}
            title={tooltipTitle}
            text={tooltipText}
            description={tooltipDescription}
            button={tooltipButton}
            close={tooltipClose}
          >
          </Tooltip>}
        </div>
        {value !== undefined && <span className='metric__value'>{typeof value === 'number' ? NumeralUtil.format(value, '0,0') : value}</span>}
        {deltaValue !== undefined && <div className='metric__delta'>
          {Math.sign(deltaValue) < 0 && <Icon className='metric__delta-icon-down' icon='stats_down' />}
          {Math.sign(deltaValue) > 0 && <Icon className='metric__delta-icon-up' icon='stats_up' />}
          <span className='metric__delta-value'>{this.getDeltaValue()}</span>
        </div>}
        </>}

        {locked && <>
        <Icon className='metric__lock-icon' icon='locker'/>
        <span className='metric__lock-title'>{title}</span>
        <div className='metric__lock-description'>
          <Description size='small'>{`Посмотреть эти данные можно, если [добавить страницу](https://suite.jagajam.com?addCommunityType=my&addCommunity=true&addUrl=${addUrl}) в JagaJam Suite`}</Description>
        </div>
        </>}
      </div>
    )
  }
}

export default Metric
