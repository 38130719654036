import React, { Component } from 'react'
import ButtonText from '../ui/elements/ButtonText/ButtonText'
import Description from '../ui/elements/Description/Description'

import Segment from '../ui/elements/Segment/Segment'
import LiteLayout from './layouts/LiteLayout'
import Title from '../ui/elements/Title/Title'
import Toolbar2 from '../ui/elements/Toolbar2/Toolbar2'

class NotFoundPage extends Component {
  render (): JSX.Element {
    return (
      <LiteLayout center icon='attention'>
        <Title>Страница не найдена</Title>
        <Segment size={3} />
        <Description size='big'>
            К сожалению, такой страницы нет. Либо её не существует, либо вы просто ошиблись.
        </Description>
        <Segment size={3} />
        <Toolbar2>
          <ButtonText to='/' color='blue'>На главную</ButtonText>
        </Toolbar2>
      </LiteLayout>
    )
  }
}

export default NotFoundPage
