import React, { Component } from 'react'

import './ADV.scss'
import Icon from '../Icon/Icon'
import ButtonText from '../ButtonText/ButtonText'

/**
 * Элемент ADV
 */

class ADV extends Component {

  render (): JSX.Element {

    return (
      <div className='adv'>
        <div className='adv__top'>
          <Icon className='adv__logo' icon='jj'/>
          <Icon className='adv__jj' icon='text_suite'/>
        </div>

        <span className='adv__title'>Помогаем оценить эффективность контента и даём рекомендации по его улучшению</span>

        <div className='adv__social'>
          {/*<span className='adv__text'>Анализируем:</span>*/}
          <Icon className='adv__icon' icon='vk'/>
          <Icon className='adv__icon' icon='ok'/>
          <Icon className='adv__icon' icon='ig'/>
          <Icon className='adv__icon' icon='fb'/>
          <Icon className='adv__icon' icon='tw'/>
          <Icon className='adv__icon' icon='tt'/>
          <Icon className='adv__icon' icon='tg'/>
          <Icon className='adv__icon' icon='yz'/>
          <Icon className='adv__icon' icon='yt'/>
        </div>

        <ButtonText to='https://suite.jagajam.com' >Попробовать JagaJam Suite</ButtonText>

        <div className='adv__bottom'>
          <span className='adv__text-small'>Тестовый доступ на 14 дней</span>
          <div className='adv__dot'/>
          <span className='adv__text-small'>Анализ 9 соцсетей</span>
          <div className='adv__dot'/>
          <span className='adv__text-small'>Добавление 10 сообществ</span>
          <div className='adv__dot'/>
          <span className='adv__text-small'>Без привязки карты</span>
        </div>
      </div>
    )
  }
}

export default ADV
