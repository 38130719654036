import React, { ChangeEvent, ChangeEventHandler, Component } from 'react'
import { Link } from 'react-router-dom'

import Image from '../Image/Image'
import Icon from '../Icon/Icon'
import NumeralUtil from '../../../utils/NumeralUtil'
import SocialUrlUtil from '../../../utils/SocialUrlUtil'

import './RatingRow.scss'
import Tag from '../Tag/Tag'

interface IProps {
  name: string
  image: string
  url: string
  tags: Array<{ id: string, name: string }>
  position: number
  usersCount: number
  to: string
  onSelect: ChangeEventHandler<HTMLInputElement | any>
}

/**
 * Элемент RatingRow.
 * Строка рейтинга
 */
class RatingRow extends Component<IProps> {

  handleTagClick = (e, tagID) => {
    e.preventDefault()
    this.props.onSelect({ target: { value: tagID } } as ChangeEvent<any>)
  }

  handleLinkClick = (e, url) => {
    e.preventDefault()
    window.open(url, '_blank')
  }

  render (): JSX.Element {
    const { name, image, url, tags, position, usersCount, to } = this.props

    const socialURL = new SocialUrlUtil(url)
    const socialType = socialURL.getSocialType()
    const screenName = socialURL.getScreenName()

    return (
      <Link className='rating-row' to={to}>
        <Image className='rating-row__image' border round src={image} noImage={require('./img/no_avatar.svg')}/>

        <div className='rating-row__content'>

          <div className='rating-row__top'>
            <span className='rating-row__name'>{name}</span>
            <div className='rating-row__badge-social'>
              <Icon className='rating-row__icon' icon={`${socialType.toLowerCase()}_colored`}/>
              {socialType === 'CH' && <span className='rating-row__social-label'>{screenName}</span>}
              {socialType !== 'CH' && <span className='rating-row__social-link' onClick={e => this.handleLinkClick(e, url)}>{screenName}</span>}
            </div>
          </div>

          <div className='rating-row__bottom'>
            <div className='rating-row__badge-place'>
              <Icon className='rating-row__place-icon' icon='admin'/>
              <span className='rating-row__place'>{position}&nbsp;место</span>
            </div>
            {tags.map(tag => (
              <Tag size='small' key={tag.id} onClick={e => this.handleTagClick(e, tag.id)}>{ tag.name }</Tag>
            ))}
          </div>

        </div>

        <div className='rating-row__metric'>
          <Icon className='rating-row__metric-icon' icon='person'/>
          <span className='rating-row__value'>{NumeralUtil.format(usersCount, '0,0')}</span>
        </div>

      </Link>
    )
  }
}

export default RatingRow
