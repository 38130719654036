import React, { ChangeEvent, ChangeEventHandler, Component, RefObject } from 'react'
import cx from 'classnames'

import './Filter.scss'
import Icon from '../Icon/Icon'
import Tag from '../Tag/Tag'
import Popup from '../Popup/Popup'
import List from '../List/List'
import TagGroup from '../Tag/TagGroup'
import ButtonText from '../ButtonText/ButtonText'
import PopupButton from '../Popup/PopupButton'
import Community from '../Community/Community'
import NumeralUtil from '../../../utils/NumeralUtil'
import Link from '../Link/Link'

interface IFilteredItem {
  id: string
  name: string
}

interface IProps {
  list: Array<IFilteredItem>
  values: Array<IFilteredItem>
  onSelect: ChangeEventHandler<HTMLInputElement | any>

  onBlur?: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
}

interface IState {
  predictList?: Array<IFilteredItem>
  inputText?: string
  isFocus?: boolean
  isOpen?: boolean
}

/**
 * Элемент Filter.
 * Поле ввода для добавления фильтров
 */

class Filter extends Component<IProps, IState> {
  private readonly ref: RefObject<any>

  static defaultProps = {
    list: [],
    values: []
  }

  state = {
    predictList: [],
    inputText: '',
    isFocus: false,
    isOpen: false
  }

  constructor (props) {
    super(props)
    this.ref = React.createRef()
  }

  focus = () => {
    setTimeout(() => {
      if (!this.props.disabled && this.ref.current) {
        this.ref.current.focus()
      }
    }, 100)
  }

  handleFocus = () => {
    this.setState({ isFocus: true })
    this.focus()
  }

  handleBlur = (e) => {
    setTimeout(() => {
      this.setState({ isFocus: false })
    }, 100)
    if (this.props.onBlur) this.props.onBlur(e)
  }

  handleClear = (e) => {
    e.target.value = ''
    this.handleInput(e)
    this.props.onSelect({ target: { value: [] } } as ChangeEvent<any>)
    focus()
  }

  handleInput = (e) => {
    const inputText = e.target.value
    const predictList = this.props.list.reduce((acc, current) => {
      if (inputText && current.name.toLowerCase().indexOf(inputText.toLowerCase()) !== -1) acc.push(current)
      return acc
    }, [])

    this.setState({ inputText, predictList })
  }

  handleRemove = (e, item: IFilteredItem) => {
    const { values } = this.props
    values.splice(values.indexOf(item), 1)
    this.props.onSelect({ target: { value: values } } as ChangeEvent<any>)
  }

  handleAppend = (e, item: IFilteredItem) => {
    const { values } = this.props
    if (values.indexOf(item) === -1) values.push(item)
    this.props.onSelect({ target: { value: values } } as ChangeEvent<any>)
    this.setState({ inputText: '', predictList: [] })
  }

  render (): JSX.Element {
    const { values } = this.props
    const { isFocus, predictList, inputText } = this.state

    const classes = cx('filter', {
      'filter--focus': isFocus,
      'filter--filled': inputText
    })

    return (
      <div className={classes} onClick={this.handleFocus}>

          <div className='filter__field-tags'>
            {values.map(item => (
              <Tag
                key={item.id}
                filter
                remove
                onClick={e => this.handleRemove(e, item)}
              >
                { item.name }
              </Tag>
              ))}
          </div>

          <div className='filter__field-input'>
            <span className='filter__label'>Фильтр страниц по категориям</span>

            <Popup
              open={isFocus && inputText && predictList.length > 0}
              maxHeight={320}
              scrolling
              onClose={this.handleBlur}
              trigger={<input
                className='filter__text'
                placeholder=''
                value={this.state.inputText}
                ref={this.ref}
                onChange={this.handleInput}
                onFocus={this.handleFocus}
                type='text'
                autoComplete='off'
              />}
            >
              <List size='superSmall' emptyText='Теги не найдены'>
                {predictList.map((item, index) => (
                  <PopupButton
                    key={item.id + index}
                    onClick={e => this.handleAppend(e, item)}
                  >
                    {item.name}
                  </PopupButton>
                ))}
              </List>
            </Popup>
          </div>
          {!!(inputText !== '' || values.length) && <Icon className='filter__icon' icon='close_circle' onClick={this.handleClear}/>}
        </div>
    )
  }
}

export default Filter
