import React, { Component, MouseEventHandler, ReactNode } from 'react'
import cx from 'classnames'

import './Tag.scss'
import Icon from '../Icon/Icon'
import { ITag } from '../../../stores/RatingTagsStore'

type Size = 'small' | 'middle'
type Color = 'grey' | 'blue' | 'red' | 'transparent'

interface IProps {
  /**
   * Текст
   */
  children?: ReactNode
  /**
   * Размер
   */
  size?: Size
  /**
   * Цвет
   */
  color?: Color
  /**
   * Обработчик клика
   */
  onClick?: MouseEventHandler
  /**
   * Ссылка
   */
  to?: string
  /**
   * Состояние выбранного тега
   */
  active?: boolean
  /**
   * Иконка удаления тега
   */
  remove?: boolean
  /**
   * Иконка
   */
  icon?: string
  /**
   * Если тег уже выбран
   */
  disable?: boolean
  /**
   * Числовое значение у тега
   */
  count?: number
  /**
   * Стиль тега для поисковой строки
   */
  filter?: boolean
  /**
   * Полный текст
   */
  fullText?: boolean
}

/**
 * Элемент Tag
 */

class Tag extends Component<IProps> {

  render (): JSX.Element {
    const { children, onClick, to, size, color, active, remove, icon, count, disable, filter, fullText } = this.props

    const classes = cx('tag', {
      [`tag--${size}`]: size,
      [`tag--${color}`]: color,
      'tag--full-text': fullText,
      'tag--active': active,
      'tag--disable': disable,
      'tag--filter': filter
    })

    return (
      <div className={classes} onClick={!disable ? onClick : undefined}>
        {icon && <Icon className='tag__icon' icon={`${icon}`}/>}
        <span className='tag__text'>{children}</span>
        {count !== undefined && <span className='tag__count'>{count}</span>}
        {remove && <Icon className='tag__remove' icon='close_circle'/>}
      </div>
    )
  }
}

export default Tag
