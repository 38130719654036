import React, { Component } from 'react'
import Icon from '../../elements/Icon/Icon'
import SocialURL from './SocialURL'

import './CommunityUrl.scss'

interface IProps {
  /**
   * Ссылка на сообщество
   */
  to: string
}

/**
 * Вид SocialInfo - иконка соцсети с краткой ссылкой на сообщество
 */
class CommunityUrl extends Component<IProps> {
  render (): JSX.Element {
    const { to } = this.props
    const socialURL = new SocialURL(to)
    return (
      <a
        href={socialURL.getCommunityLink()}
        target='_blank'
        rel='noopener noreferrer'
        className='community-url'
      >
        <Icon
          className='community-url__icon'
          icon={`${socialURL.getSocialType()}_colored`}
        />
        <span className='community-url__text'>
          {socialURL.getScreenName()}
        </span>
      </a>
    )
  }
}

export default CommunityUrl
