import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { NavLink, Link as ReactLink } from 'react-router-dom'

import './Link.scss'

/**
 * Элемент Link
 */
class Link extends Component {
  static propTypes = {
    /**
     * Дополнительные классы
     */
    className: PropTypes.string,
    /**
     * Активная ссылка
     */
    active: PropTypes.bool,
    /**
     * Аттрибут style
     */
    style: PropTypes.object,
    /**
     * Ссылка
     */
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /**
     * Ссылка mailto:
     */
    mailto: PropTypes.string,
    /**
     * Ссылка tel:
     */
    tel: PropTypes.string,
    /**
     * Аттрибут download
     */
    download: PropTypes.bool,
    /**
     * Открывается ли ссылка в новом окне
     */
    _blank: PropTypes.bool,
    /**
     * Аттрибут Title
     */
    title: PropTypes.string,
    /**
     * Обработчик клика
     */
    onClick: PropTypes.func,
    /**
     * Текст ссылки
     */
    children: PropTypes.node,
    /**
     * Точное соответствие для url
     */
    exact: PropTypes.bool,
    onRef: PropTypes.func
  }

  static defaultProps = {
    to: '',
    _blank: false
  }

  static contextTypes = {
    i18n: PropTypes.object.isRequired
  }

  render () {
    const { className, active, mailto, tel, download, _blank, title, children, onClick, style, exact, onRef } = this.props
    let { to } = this.props
    const { locale } = this.context && (this.context.i18n || {})
    to = to && to.replace('/:locale', `/${locale}`)

    const classes = cx('link', className, { 'link--active': active })

    if (mailto) return <a title={title} style={style} href={`mailto:${mailto}`} className={classes} ref={onRef} onClick={onClick}>{children}</a>
    if (tel) return <a title={title} style={style} href={`tel:${tel}`} className={classes} ref={onRef} onClick={onClick}>{children}</a>
    if (to) {
      if (_blank) {
        return (
          <a
            title={title}
            style={style}
            href={to}
            target='_blank'
            rel='noopener noreferrer'
            className={classes}
            ref={onRef}
            onClick={onClick}
          >
            {children}
          </a>)
      }
      if (to.indexOf('http://') !== -1 || to.indexOf('https://') !== -1) {
        return <a title={title} style={style} href={to} onClick={onClick} className={classes} ref={onRef} download={download}>{children}</a>
      } else if (onRef) {
        return <ReactLink exact={exact} title={title} style={style} to={to} className={classes} activeClassName='active' onClick={onClick} innerRef={onRef}>{children}</ReactLink>
      } else {
        return <NavLink exact={exact} title={title} style={style} to={to} className={classes} activeClassName='active' onClick={onClick}>{children}</NavLink>
      }
    } else return <span title={title} style={style} className={classes} onClick={onClick} ref={onRef}>{children}</span>
  }
}

export default Link
