import { action, makeAutoObservable } from 'mobx'
import { createForm, MobxForm } from '../utils/mobx-form-creator'
import APIClient from '../lib/APIClient'

interface ILoginForm {
  email: string
  password: string
}

interface IProfile {
  userID: string
  email: string
  name: string
  image: string
  role: string
}

export default class ProfileStore {
  public loginForm: MobxForm<ILoginForm> = {}
  public profile: IProfile = {
    userID: '',
    email: '',
    name: '',
    image: '',
    role: ''
  }
  public isLoading: boolean = false
  public isAuth: boolean = false
  public token: string = ''

  constructor () {
    makeAutoObservable(this)
    this.token = localStorage.getItem('token') || ''

    if (this.token) this.load()

    createForm<ILoginForm>(this.loginForm,{
      email: '',
      password: ''
    })
  }

  @action
  public async login (): Promise<void> {
    try {
      const data = await APIClient.get('signin', {
        email: this.loginForm.email.value,
        password: this.loginForm.password.value
      })

      this.token = data.data.data.token
      await this.load()
    } catch (e) {
      console.log(e)

      return this.loginForm.setErrors(e.response.data.meta.errors)
    }
  }

  @action
  public async load (silentLoading: boolean = false): Promise<void> {
    if (!silentLoading) this.isLoading = true

    try {
      const data = await APIClient.get('profile/personal', {
        fields: 'email,name,role,confirmedEmail',
        token: this.token
      })

      const profile = data.data.data

      this.isAuth = true
      localStorage.setItem('token', this.token)
      this.profile = {
        userID: profile.userID,
        email: profile.email,
        name: profile.name,
        image: profile.image,
        role: profile.role
      }
    } catch (e) {
      console.log('ProfileStore Error', e)

      this.isAuth = false
      this.token = ''
      localStorage.removeItem('token')
    }

    this.isLoading = false
  }

  /* @action
  public async update (from, to, sort, direction): Promise<void> {
    await APIClient.patch('profile', {
      token: this.token,
      dashboard_start_period: from,
      dashboard_end_period: to,
      dashboard_sort: sort,
      dashboard_direction: direction,
    })
  } */

  @action
  public logout (): void {
    this.isAuth = false
    localStorage.removeItem('token')
  }
}
