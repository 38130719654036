import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Icon from '../Icon/Icon'

import './SocialSelector.scss'

/**
 * Элемент SocialSelector.
 * Выбор рейтинга в других социальных сетях
 */

class SocialSelector extends Component {

  render (): JSX.Element {

    return (
      <div className='social-selector'>
        <span className='social-selector__text'>Рейтинг по другим соцсетям:</span>
        <Link className='social-selector__link' to='/rating/vk'><Icon className='social-selector__icon' icon='vk'/></Link>
        <Link className='social-selector__link' to='/rating/ok'><Icon className='social-selector__icon' icon='ok'/></Link>
        <Link className='social-selector__link' to='/rating/inst'><Icon className='social-selector__icon' icon='ig'/></Link>
        <Link className='social-selector__link' to='/rating/fb'><Icon className='social-selector__icon' icon='fb'/></Link>
        <Link className='social-selector__link' to='/rating/tw'><Icon className='social-selector__icon' icon='tw'/></Link>
        <Link className='social-selector__link' to='/rating/tt'><Icon className='social-selector__icon' icon='tt'/></Link>
        <Link className='social-selector__link' to='/rating/tg'><Icon className='social-selector__icon' icon='tg'/></Link>
        <Link className='social-selector__link' to='/rating/yz'><Icon className='social-selector__icon' icon='yz'/></Link>
        <Link className='social-selector__link' to='/rating/ch'><Icon className='social-selector__icon' icon='ch'/></Link>
        <Link className='social-selector__link' to='/rating/rt'><Icon className='social-selector__icon' icon='rt'/></Link>
        <Link className='social-selector__link' to='/rating/yt'><Icon className='social-selector__icon' icon='yt'/></Link>
      </div>
    )
  }
}

export default SocialSelector
