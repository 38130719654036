import development from './development'
import production from './production'
import common from './common'

const config = process.env.NODE_ENV === 'production' ? production : development

const fullConfig = {
  ...config,
  ...common
}

export default fullConfig
