import { CancelTokenSource } from 'axios'
import { action, makeAutoObservable } from 'mobx'
import APIClient from '../lib/APIClient'
import whenChangedParameters from '../utils/whenChangedParameters'
import BaseAPI from './API/BaseAPI'
import ICommunity from '../interfaces/ICommunity'

export interface ITopCommunity extends ICommunity {
  position: number
  tags: Array<string>
  suggestedTags: Array<string>
  socials: Map<string, string>
  socialCategories: string
}

export default class RatingTopStore {
  public isLoading: boolean = false
  public communities: Array<ITopCommunity> = []
  public page: number = 1
  public nextPage: number = 1
  private _cancelSource: CancelTokenSource = null

  constructor () {
    makeAutoObservable(this)
  }

  @action
  @whenChangedParameters
  public async load (socialType: string, tags: Array<string>, page: number): Promise<void> {
    this.isLoading = true
    if (this._cancelSource) this._cancelSource.cancel()
    this._cancelSource = BaseAPI.cancelSource()

    try {
      const data = await APIClient.get('rating/top', {
        socialType,
        tags: (tags || []).join(','),
        page: page,
        perPage: 50,
        fields: 'socialType,groupID'
      }, this._cancelSource.token)

      const communities = data.data.data

      this.nextPage = data.data.pagination.nextPage

      if (page === 1) this.communities = communities
      else this.communities = this.communities.concat(communities)

      console.log('RatingTopStore communities', communities)

      this.isLoading = false
    } catch (e) {
      if (e.__CANCEL__) return

      console.log('RatingTopStore', e.response)
      // validationErrors: e.response.data.meta.errors
      return
    }
  }
}
