import React, { Component, MouseEventHandler, ReactNode } from 'react'
import cx from 'classnames'

import Icon from '../Icon/Icon'
import { IIcon } from '../Icon/Icons'

import './PopupButton.scss'
import ButtonText from '../ButtonText/ButtonText'

interface IProps {
  /**
   * Иконка
   */
  icon?: IIcon | string
  /**
   * Текст
   */
  children: ReactNode
  /**
   * Активное состояние
   */
  active?: boolean
  /**
   * Отключенное состояние
   */
  disabled?: boolean
  /**
   * Ссылка
   */
  to?: string
  /**
   * Обработчик клика
   */
  onClick?: MouseEventHandler
  /**
   * Используется для автоматического закрытия Popup
   */
  autoClosePopup?: boolean
  /**
   * Чекбокс
   */
  checked?: boolean
}

/**
 * Блок PopupButton
 */
export default class PopupButton extends Component<IProps> {

  render (): JSX.Element {
    const { icon, children, active, disabled, to, onClick, checked } = this.props

    const classes = cx('popup-button', {
      'popup-button--active': active,
      'popup-button--disabled': disabled,
      'popup-button--checked': checked
    })

    // todo: Перенести галочку в ButtonText
    return (
      <ButtonText position='left' className={classes} to={to} onClick={!disabled && onClick} icon={icon}>
        {children}
        {checked && <Icon className='popup-button__check' icon='complete' />}
      </ButtonText>)
  }
}
