import React, { ChangeEvent, ChangeEventHandler, Component, RefObject } from 'react'
import cx from 'classnames'

import './FilterSocial.scss'
import Popup from '../Popup/Popup'
import Icon from '../Icon/Icon'
import PopupButton from '../Popup/PopupButton'

interface IProps {
  socialType: string
}

/**
 * Элемент FilterSocial.
 * Фильтр по социальной сети
 */
class FilterSocial extends Component<IProps> {

  render (): JSX.Element {

    const { socialType } = this.props
    return (
      <div className='filter-social'>

        {socialType &&
        <Popup
          maxHeight={540}
          size='small'
          right
          trigger={
            <div className='filter-social__network'>
              <Icon className='filter-social__icon' icon={`${socialType.toLowerCase()}_colored`} />
            </div>}
        >
          { ['VK', 'OK', 'INST', 'FB', 'TW', 'TT', 'TG', 'YZ', 'CH', 'RT', 'YT'].map(item => (
            <PopupButton
              key={item}
              autoClosePopup
              icon={`${item.toLowerCase()}_colored`}
              to={`/rating/${item.toLowerCase()}`}
            >
              {{
                VK : 'ВКонтакте',
                OK : 'Одноклассники',
                INST : 'Instagram',
                FB : 'Facebook',
                TW : 'Twitter',
                TT : 'TikTok',
                TG : 'Telegram',
                YZ : 'Яндекс.Дзен',
                CH : 'Clubhouse',
                RT : 'Rutube',
                YT : 'YouTube',
              }[item] || item}
            </PopupButton>
          ))}
        </Popup>
        }

      </div>
    )
  }
}

export default FilterSocial
