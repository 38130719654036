import React, { Component } from 'react'
import { debounce } from 'lodash'
import { Helmet } from 'react-helmet'

import { Stores } from '../../stores/RootStore'
import withParams, { ParamsProps } from '../../utils/withParams'
import LiteLayout from '../layouts/LiteLayout'
import Segment from '../../ui/elements/Segment/Segment'
import { inject, observer } from 'mobx-react'
import InputText from '../../ui/elements/InputText/InputText'
import Title from '../../ui/elements/Title/Title'
import Description from '../../ui/elements/Description/Description'
import List from '../../ui/elements/List/List'
import RatingRow from '../../ui/elements/RatingRow/RatingRow'
import SearchStore from '../../stores/SearchStore'
import Popup from '../../ui/elements/Popup/Popup'
import Community from '../../ui/elements/Community/Community'
import NumeralUtil from '../../utils/NumeralUtil'
import RatingTopStore from '../../stores/RatingTopStore'
import Loading from '../../ui/elements/Loading/Loading'
import NoData from '../../ui/elements/NoData/NoData'
import RatingTagsStore from '../../stores/RatingTagsStore'
import SocialSelector from '../../ui/elements/SocialSelector/SocialSelector'
import Filter from '../../ui/elements/Filter/Filter'
import FilterSocial from '../../ui/elements/Filter/FilterSocial'
import Toolbar2 from '../../ui/elements/Toolbar2/Toolbar2'
import Toolbar2Group from '../../ui/elements/Toolbar2/Toolbar2Group'
import ADVSmall from '../../ui/elements/ADV/ADVSmall'
import TagGroup from '../../ui/elements/Tag/TagGroup'
import Tag from '../../ui/elements/Tag/Tag'

interface IParams {
  social?: string
  tags?: Array<string>
  communityUrl?: string
}

interface IProps {
  params?: ParamsProps<IParams>
  searchStore?: SearchStore
  ratingTopStore?: RatingTopStore
  ratingTagsStore?: RatingTagsStore
}

interface IStates {
  q: string
  page: number
  socialType: string
}

@withParams
@inject(Stores.SEARCH_STORE, Stores.RATING_TOP_STORE, Stores.RATING_TAGS_STORE)
@observer
class RatingPage extends Component<IProps, IStates> {
  state = {
    q: '',
    page: 1,
    socialType: ''
  }

  constructor (props: IProps) {
    super(props)

    this.props.params.setDefaultParams({
      tags: []
    })

    this.load()
  }

  componentDidMount() {
    setTimeout(() => {
      this.handleSearch(this.props.params.communityUrl)
    }, 100)
  }

  componentDidUpdate (prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    this.load()
  }

  load = () => {
    const { params, ratingTopStore, ratingTagsStore } = this.props
    const tags = params.tags
    const social = params.social.toUpperCase()
    let { socialType, page } = this.state
    if (socialType !== social) {
      socialType = social
      page = 1
      this.setState({ page, socialType })
    }

    ratingTopStore.load(socialType, tags, page)
    ratingTagsStore.load(socialType, tags)
  }

  handleSearch = (q) => {
    this.setState({ q: q })

    this.debounceSearch(q)

    if (this.props.params.communityUrl) this.props.params.changeParams({ communityUrl: '' })
  }

  debounceSearch = debounce(q => {
    const { params, searchStore } = this.props
    if (q) searchStore.load(params.social.toUpperCase(), q)
  }, 500)

  handleLoadMore = () => {
    const { ratingTopStore } = this.props

    if (!ratingTopStore.isLoading && ratingTopStore.nextPage) {
      this.setState({ page: ratingTopStore.nextPage })
    }
  }

  handleChangeTags = (tags: Array<string> | string) => {
    const params = this.props.params
    let result
    if (Array.isArray(tags)) {
      result = tags
    } else {
      result = params.tags || []
      if (result.indexOf(tags) === -1) result.push(tags)
    }
    this.setState({ page: 1 })
    params.changeParams({ tags: result })
  }

  getSocialTypeName = (socialType) => {
    if (socialType === 'VK') return 'Вконтакте'
    if (socialType === 'OK') return 'Одноклассники'
    if (socialType === 'INST') return 'Instagram'
    if (socialType === 'FB') return 'Facebook'
    if (socialType === 'TW') return 'Twitter'
    if (socialType === 'TT') return 'TikTok'
    if (socialType === 'TG') return 'Telegram'
    if (socialType === 'YZ') return 'Яндекс Дзен'
    if (socialType === 'CH') return 'Clubhouse'
    if (socialType === 'RT') return 'Rutube'
    if (socialType === 'YT') return 'Youtube'
  }

  render (): JSX.Element {
    const { params, searchStore, ratingTopStore, ratingTagsStore } = this.props
    const { q, socialType } = this.state

    const socialTypeName = this.getSocialTypeName(socialType)
    const allTags = ratingTagsStore.tags.map(tag => ({ id: tag.tagID, name: ratingTagsStore.getName(tag.tagID), count: tag.count, parent: tag.parent }))
    const selectedTags = params.tags
      ? params.tags.map(tagID => ({ id: tagID, name: ratingTagsStore.getName(tagID) }))
      : []

    let sortedTags = allTags.filter(tag => tag.count > 0).sort((a, b) => b.count - a.count)
    const maxCountTags = sortedTags[0] ? sortedTags[0].count : 0
    sortedTags = sortedTags.filter(tag => tag.count < maxCountTags)
    console.log(maxCountTags)

    return (
      <LiteLayout
        subHeader={
          <>
            <Segment size={10} center>
              <Title center white size='superBig' text={`Рейтинг ${socialTypeName}`}/>
              <Segment size={2}/>
              <Description center white size='big'>
                {
                  `Мы составляем самый полный рейтинг страниц ${socialTypeName}.\\r
                  Найдите свою и посмотрите, возможно именно она сегодня круче всех!`
                }
              </Description>
            </Segment>

            <Segment size={5} maxWidth={730}>
              <Popup
                open={!!this.state.q}
                onClose={() => this.setState({ q: '' })}
                trigger={
                  <InputText
                    label='Введите название страницы, никнейм или её имя'
                    value={q}
                    onChange={e => this.handleSearch(e.target.value)}
                    onBlur={e => { if (!e.target.value) this.setState({ q: '' }) }}
                    focus
                    rating
                  />
                }
                scrolling
                maxHeight={520}
              >
                <List isLoading={searchStore.isLoading} emptyText='Страницы не найдены'>
                  { searchStore.communities.map(community => {
                    return (<Community
                      key={community.cid}
                      image={community.image}
                      name={community.name}
                      url={community.url}
                      usersCount={NumeralUtil.format(community.usersCount, '0,0')}
                      onClick={() => params.changeUrl(`/rating/${community.socialType.toLowerCase()}/${community.screenName}/${community.groupID}`)}
                      />)
                  })}
                </List>
              </Popup>
            </Segment>

            <Segment size={5}>
              <SocialSelector />
            </Segment>

            <Segment size={10}/>
          </>
        }
      >
        <Helmet>
          <title>JagaJam Rating - JagaJam Рейтинг страниц</title>
        </Helmet>

        <Segment size={10}>
          <Toolbar2 size='regular'>
            <Toolbar2Group>
              <FilterSocial socialType={socialType}/>
            </Toolbar2Group>
              <Toolbar2Group fill>
              <Filter
                values={selectedTags}
                list={allTags}
                onSelect={e => this.handleChangeTags(e.target.value.map(x => x.id))}
              />
            </Toolbar2Group>
          </Toolbar2>
        </Segment>

        {!!sortedTags.length && (
          <Segment size={2}>
            <TagGroup oneLine>
              {sortedTags.map(tag => (
                <Tag onClick={() => this.handleChangeTags(tag.id)}>{tag.name}</Tag>
              ))}
            </TagGroup>
          </Segment>
        )}

        <Segment size={10}>
          <Title center text='Самые популярные страницы'/>
          <Segment size={2}/>
          <Description center size='big'>
            {`Мы постоянно пополняем свою базу данных. На данный момент рейтинг самых популярных страниц ${socialTypeName} выглядит так:`}
          </Description>
        </Segment>

        <Segment size={5}>
          <List size='small' isOverLoading={ratingTopStore.isLoading} onLoadMore={() => this.handleLoadMore()}>
          {ratingTopStore.communities.map(community => (
            <>
              <RatingRow
                key={community.position}
                name={community.name}
                image={community.image}
                url={community.url}
                tags={community.tags.map(tagID => ({ id: tagID, name: ratingTagsStore.getName(tagID) }))}
                position={community.position}
                usersCount={community.usersCount}
                to={`/rating/${community.socialType.toLowerCase()}/${community.screenName}/${community.groupID}`}
                onSelect={e => this.handleChangeTags(e.target.value)}
              />
              {community.position % 25 === 0 && <ADVSmall/>}
            </>
          ))}
          </List>
          {ratingTopStore.isLoading && <Segment size={1}><Loading size={100}>Распределяем места</Loading></Segment>}
        </Segment>

        {!ratingTopStore.isLoading && ratingTopStore.communities.length < 200 &&
        <Segment size={5}>
          <NoData>Больше мы ничего не нашли</NoData>
        </Segment>
        }
        <Segment size={7}/>
      </LiteLayout>
    )
  }
}

export default RatingPage
