import axios, { AxiosPromise, AxiosRequestConfig, CancelToken, CancelTokenSource } from 'axios'
import config from '../config'
import ObjectUtil from '../utils/ObjectUtil'
import qs from 'qs'

const { apiUrl, analyticsApi } = config

axios.interceptors.request.use((response) => {
  response.paramsSerializer = params => {
    params = qs.stringify(params, {
      encodeValuesOnly: true
    })
    return params
  }

  return response
}, (error) => {
  return Promise.reject(error)
})

axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  return Promise.reject(error)
})

const axiosRetry = (options: AxiosRequestConfig): AxiosPromise => {
  return new Promise(async (resolve, reject) => {
    let loop = true
    while (loop) {
      loop = false
      await new Promise((resolve, reject) => {
        // Делаем запрос на API
        return axios(options)
          .then(data => {
            resolve(data)
          })
          .catch(error => {
            if (!error.__CANCEL__) {
              if (!error.response || (error.response && error.response.status === 408)) {
                console.log('axiosRetry:retry')
                setTimeout(() => { reject(error) }, 10000)
              } else {
                console.log('axiosRetry:resolve')
                resolve(error.response)
                return
              }
            }
          })
      }).then((data: any) => {
        return resolve(data)
      }).catch(() => {
        // Если ошибка - повторяем попытку
        loop = true
      })
    }
  })
}

export default class APIClient {
  public static async get (method, params = {}, cancel: CancelToken = null): Promise<AxiosPromise> {
    return axiosRetry({
      url: apiUrl.concat(method),
      params: ObjectUtil.removeUndefined(params),
      timeout: 0,
      method: 'get',
      responseType: 'json',
      cancelToken: cancel
    })
  }

  public static async post (method, params = {}, cancel: CancelToken = null): Promise<AxiosPromise> {
    return axiosRetry({
      url: apiUrl.concat(method),
      data: ObjectUtil.removeUndefined(params),
      timeout: 0,
      method: 'post',
      responseType: 'json',
      cancelToken: cancel
    })
  }

  public static async postAnalytics (method, params = {}, cancel: CancelToken = null): Promise<AxiosPromise> {
    return axiosRetry({
      url: analyticsApi.concat(method),
      data: ObjectUtil.removeUndefined(params),
      timeout: 0,
      method: 'post',
      responseType: 'json',
      cancelToken: cancel
    })
  }

  public static async patch (method, params = {}, cancel: CancelToken = null): Promise<AxiosPromise> {
    return axiosRetry({
      url: apiUrl.concat(method),
      data: ObjectUtil.removeUndefined(params),
      timeout: 0,
      method: 'patch',
      responseType: 'json',
      cancelToken: cancel
    })
  }

  public static async delete (method, params = {}, cancel: CancelToken = null): Promise<AxiosPromise> {
    return axiosRetry({
      url: apiUrl.concat(method),
      data: ObjectUtil.removeUndefined(params),
      timeout: 0,
      method: 'delete',
      responseType: 'json',
      cancelToken: cancel
    })
  }

  public static cancelSource (): CancelTokenSource {
    const CancelToken = axios.CancelToken
    return CancelToken.source()
  }
}
