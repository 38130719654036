import React, { Component } from 'react'

import './AddTags.scss'
import TagGroup from '../Tag/TagGroup'
import Tag from '../Tag/Tag'
import ButtonText from '../ButtonText/ButtonText'
import { inject, observer } from 'mobx-react'
import { Stores } from '../../../stores/RootStore'
import RatingTagsStore from '../../../stores/RatingTagsStore'
import RatingTopCommunityStore from '../../../stores/RatingTopCommunityStore'
import Title from '../Title/Title'
import Segment from '../Segment/Segment'
import Description from '../Description/Description'
import Popup from '../Popup/Popup'
import InputText from '../InputText/InputText'
import PopupButton from '../Popup/PopupButton'
import Loading from '../Loading/Loading'

interface IProps {
  ratingTopCommunityStore?: RatingTopCommunityStore
  ratingTagsStore?: RatingTagsStore
}

interface IState {
  isOpen: boolean
  isFocus: boolean
  tagsList: Array<string>
  suggestedTags: Array<string>
  predictedTags: Array<string>
  inputText: string
}

/**
 * Элемент AddTags
 */
@inject(Stores.RATING_TOP_COMMUNITY_STORE, Stores.RATING_TAGS_STORE)
@observer
class AddTags extends Component<IProps, IState> {
  state = {
    isOpen: false,
    isFocus: false,
    tagsList: [],
    suggestedTags: [],
    predictedTags: [],
    inputText: ''
  }

  open = async () => {
    const { ratingTagsStore, ratingTopCommunityStore } = this.props
    const community = ratingTopCommunityStore.community
    await ratingTagsStore.load(community.socialType, [])

    const communityTags = community.tags.map(tag => tag.tagID) // список тегов сообщества
    const suggestedTags = community.suggestedTags.map(tag => tag.tagID) // список тегов для аппрува
    const tagsList = [...this.props.ratingTagsStore.tags.map(tag => tag.tagID)] // список всех тегов

    const existsTags = communityTags.concat(suggestedTags)
    existsTags.forEach(tagID => {
      const index = tagsList.indexOf(tagID)
      if (index !== -1) tagsList.splice(index, 1)
    })

    this.setState({
      isOpen: true,
      tagsList,
      suggestedTags
    })
  }

  suggestTag = (tagID: string) => {
    this.props.ratingTagsStore.suggestTags(this.props.ratingTopCommunityStore.community.cid, [tagID])
    const { tagsList, suggestedTags } = this.state
    tagsList.splice(tagsList.indexOf(tagID), 1)
    suggestedTags.push(tagID)
    this.setState({
      tagsList,
      suggestedTags,
      predictedTags: [],
      inputText: ''
    })
  }

  handlePredict = (value) => {
    const { tagsList } = this.state
    const predictedTags = tagsList.reduce((acc, current) => {
      if (value && this._getTagNameByID(current).toLowerCase().indexOf(value.toLowerCase()) !== -1) acc.push(current)
      return acc
    }, [])

    this.setState({ inputText: value, predictedTags })
  }

  handleBlur = (e) => {
    const state = { isFocus: false }
    if (!e.target.value) state['inputText'] = ''
    setTimeout(() => {
      this.setState(state)
    }, 100)
  }

  _getTagNameByID = (tagID: string): string => this.props.ratingTagsStore.getName(tagID)

  render (): JSX.Element {
    const { ratingTagsStore } = this.props
    const { isOpen, isFocus, inputText, suggestedTags, predictedTags } = this.state

    if (ratingTagsStore.isLoading) return <Loading size={50}>Загружаем теги</Loading>

    return (
      <div className='add-tags'>
        { !isOpen && <ButtonText full onClick={ () => this.open() } >Добавить теги к аккаунту</ButtonText> }
        {isOpen &&
        <Segment>
            <Segment>
              <Popup
                open={isFocus && predictedTags.length > 0}
                maxHeight={300}
                scrolling
                onClose={e => this.handleBlur(e)}
                trigger={<InputText
                  label='Введите название тега'
                  value={inputText}
                  onChange={e => this.handlePredict(e.target.value)}
                  onFocus={() => this.setState({ isFocus: true }) }
                  focus
                  rating
                  onWhite
                />}
              >
                {predictedTags.map((tagID, index) => (
                  <PopupButton
                    key={tagID + index + 1}
                    onClick={() => this.suggestTag(tagID)}
                  >
                    {this._getTagNameByID(tagID)}
                  </PopupButton>
                ))}
              </Popup>
            </Segment>
            {!!suggestedTags.length &&
              <>
                <Segment size={5}/>
                <Title center text='Предложенные теги'/>
                <Segment size={3}/>
                <Description center size='big'>
                  Мы рассмотрим добавление к этой странице выбранных тегов:
                </Description>
                <Segment size={5}/>
                <TagGroup>
                  {
                    suggestedTags.map((tagID, index) => (
                      <Tag
                        key={tagID + index + 2}
                      >
                        {this._getTagNameByID(tagID)}
                      </Tag>
                    ))
                  }
                </TagGroup>
              </>
            }
          </Segment>
        }
      </div>
    )
  }
}

export default AddTags
