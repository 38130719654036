import React, { Component } from 'react'

import './ADVSmall.scss'
import Icon from '../Icon/Icon'
import ButtonText from '../ButtonText/ButtonText'

/**
 * Элемент ADVSmall
 */

class ADVSmall extends Component {

  render (): JSX.Element {

    return (
      <div className='adv-small'>

        <div className='adv-small__left'>
          <Icon className='adv-small__logo' icon='jj'/>
          <Icon className='adv-small__jj' icon='text_suite'/>
        </div>

        <div className='adv-small__message'>
          <span className='adv-small__text'>Помогаем оценить эффективность контента в социальных сетях</span>
          <span className='adv-small__text'>и даём рекомендации по его улучшению</span>
        </div>

        <ButtonText to='https://suite.jagajam.com'>Попробовать бесплатно</ButtonText>

      </div>
    )
  }
}

export default ADVSmall
