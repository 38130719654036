import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Stores } from '../stores/RootStore'
import withParams, { ParamsProps } from '../utils/withParams'
import { Helmet } from 'react-helmet'

import LiteLayout from './layouts/LiteLayout'
import Title from '../ui/elements/Title/Title'
import ButtonText from '../ui/elements/ButtonText/ButtonText'
import RatingTopCommunityStore from '../stores/RatingTopCommunityStore'
import Segment from '../ui/elements/Segment/Segment'
import RatingInfo from '../ui/elements/RatingInfo/RatingInfo'
import Toolbar2 from '../ui/elements/Toolbar2/Toolbar2'
import RatingTagsStore from '../stores/RatingTagsStore'
import TagGroup from '../ui/elements/Tag/TagGroup'
import Tag from '../ui/elements/Tag/Tag'
import FloatingRow from '../ui/elements/FloatingRow/FloatingRow'
import Popup from '../ui/elements/Popup/Popup'
import InputText from '../ui/elements/InputText/InputText'
import List from '../ui/elements/List/List'
import PopupButton from '../ui/elements/Popup/PopupButton'
import Loading from '../ui/elements/Loading/Loading'

interface IParams {
  cid?: string
}

interface IProps {
  params?: ParamsProps<IParams>
  ratingTopCommunityStore?: RatingTopCommunityStore
  ratingTagsStore?: RatingTagsStore
}

interface IState {
  tags: Array<string>,
  predictedTags: Array<string>
  inputText: string
}

/**
 * Страница Statistics
 */
@withParams
@inject(Stores.RATING_TOP_COMMUNITY_STORE, Stores.RATING_TOP_STORE, Stores.RATING_TAGS_STORE)
@observer
class ApprovalPage extends Component<IProps, IState> {
  state = {
    tags: [],
    predictedTags: [],
    inputText: ''
  }

  constructor (props: IProps) {
    super(props)

    this.load()
  }

  load = async () => {
    const { params, ratingTagsStore } = this.props
    await ratingTagsStore.loadCommunityTagsToApprove(params.cid)
    await ratingTagsStore.loadTagsApproveStatistic()
    const tags = [...ratingTagsStore.community.tags, ...ratingTagsStore.community.suggestedTags]
    await ratingTagsStore.load(ratingTagsStore.community.socialType, tags)
    this.setState({ tags })
  }

  toggleTag = (tag) => {
    const { ratingTagsStore } = this.props
    const tags: Array<string> = this.state.tags
    const index = tags.indexOf(tag)
    if (index === -1) {
      tags.push(tag)
    } else {
      tags.splice(index, 1)
    }

    ratingTagsStore.load(ratingTagsStore.community.socialType, tags)

    this.setState({ tags: tags, predictedTags: [], inputText: '' })
  }

  applyTags = async () => {
    const { ratingTagsStore } = this.props
    const tags: Array<string> = this.state.tags
    const isSuccess = await ratingTagsStore.approveTags(ratingTagsStore.community.cid, tags)
    if (isSuccess) this.load()
  }

  handlePredict = (value) => {
    const tags = this.props.ratingTagsStore.tags
    const predictedTags = tags.reduce((acc, current) => {
      if (value && this._getTagNameByID(current.tagID).toLowerCase().indexOf(value.toLowerCase()) !== -1) acc.push(current.tagID)
      return acc
    }, []).sort((a, b) => this._getTagNameByID(a).length - this._getTagNameByID(b).length)

    this.setState({ inputText: value, predictedTags })
  }

  _getTagNameByID = (tagID: string): string => this.props.ratingTagsStore.getFullName(tagID)

  render (): JSX.Element {
    const { ratingTagsStore } = this.props
    const { tags, predictedTags, inputText } = this.state

    if (ratingTagsStore.isLoading) return <Loading size={50}>Загружаем категории</Loading>

    const suggestedTags = tags.reduce((acc, current) => {
      (ratingTagsStore.community.tags.includes(current)) ? acc.unshift(current) : acc.push(current)
      return acc
    }, [])

    let sortedTags = ratingTagsStore.tags.filter(tag => tag.count > 0).sort((a, b) => b.count - a.count)
    const maxCountTags = sortedTags[0] ? sortedTags[0].count : 0
    sortedTags = sortedTags.filter(tag => tag.count < maxCountTags)

    return (
      <LiteLayout>
        <Helmet>
          <title>JagaJam Rating - Approval</title>
        </Helmet>

        <Segment size={0}>
          <Toolbar2>
            <ButtonText icon='arrow_left' to='/'>Назад к рейтингу</ButtonText>
            <Title size='small' text={`Ещё ${ratingTagsStore.tasksCount} в очереди на проверку`}/>
          </Toolbar2>
        </Segment>

        <Segment size={3}>
          <RatingInfo
            image={ratingTagsStore.community.image}
            name={ratingTagsStore.community.name}
            url={ratingTagsStore.community.url}
            usersCount={ratingTagsStore.community.usersCount}
            socials={ratingTagsStore.community.socials}
            tags={ratingTagsStore.community.tags.map(tagID => ({ tagID, name: this._getTagNameByID(tagID) }))}
            description={ratingTagsStore.community.description}
            webSite={ratingTagsStore.community.webSite}
            socialCategories={ratingTagsStore.community.socialCategories}
            fullDescription
          />
        </Segment>

        <Segment size={3}>
          <TagGroup>
            {suggestedTags.map((tagID, index) => (
              <Tag
                key={tagID + index + 1}
                remove
                active={(ratingTagsStore.community.tags).includes(tagID)}
                onClick={() => this.toggleTag(tagID)}
                fullText
              >
                {this._getTagNameByID(tagID)}
              </Tag>
            ))}
          </TagGroup>

          <Segment size={3}>
            <Popup
              open={predictedTags.length > 0}
              trigger={<InputText
                label='Введите название тега'
                value={inputText}
                onChange={e => this.handlePredict(e.target.value)}
                onBlur={e => { if (!e.target.value) this.setState({ inputText: '' }) }}
                focus
                rating
                onWhite
              />}
              scrolling
              maxHeight={300}
            >
              <List size='superSmall' emptyText='Теги не найдены'>
                {predictedTags.map((tagID, index) => (
                  <PopupButton
                    key={tagID + index + 2}
                    onClick={() => this.toggleTag(tagID)}>
                    {this._getTagNameByID(tagID)}
                  </PopupButton>
                ))}
              </List>
            </Popup>
          </Segment>
        </Segment>

        <Segment size={1}>
          <TagGroup>
            {sortedTags.map((tag, index) => (
              <Tag
                key={tag.tagID + index + 3}
                onClick={() => this.toggleTag(tag.tagID)}
                fullText
              >
                {tag.name}
              </Tag>
            ))}
          </TagGroup>
        </Segment>

        <FloatingRow
          onConfirmClick={() => { this.applyTags() }}
          onCancelClick={() => this.load()}
          confirmBtnText='Подтвердить'
          cancelBtnText={ this.props.params.cid ? null : 'Пропустить'}
        />

        <Segment size={10}/>

      </LiteLayout>
    )
  }
}

export default ApprovalPage
