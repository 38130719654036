import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Stores } from '../../stores/RootStore'
import withParams, { ParamsProps } from '../../utils/withParams'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import LiteLayout from '../layouts/LiteLayout'
import Title from '../../ui/elements/Title/Title'
import ButtonText from '../../ui/elements/ButtonText/ButtonText'
import RatingTopCommunityStore from '../../stores/RatingTopCommunityStore'
import Segment from '../../ui/elements/Segment/Segment'
import RatingInfo from '../../ui/elements/RatingInfo/RatingInfo'
import Description from '../../ui/elements/Description/Description'
import RatingMetric from '../../ui/elements/RatingMetric/RatingMetric'
import RatingMetricGroup from '../../ui/elements/RatingMetric/RatingMetricGroup'
import LookAlike from '../../ui/elements/LookAlike/LookAlike'
import LineWidgetChart from '../../ui/elements/statistics/widgets/LineWidgetChart/LineWidgetChart'
import Toolbar2 from '../../ui/elements/Toolbar2/Toolbar2'
import Loading from '../../ui/elements/Loading/Loading'
import RatingTagsStore from '../../stores/RatingTagsStore'
import ADV from '../../ui/elements/ADV/ADV'
import AddTags from '../../ui/elements/AddTags/AddTags'
import ProfileStore from '../../stores/ProfileStore'
import MetricGroup from '../../ui/elements/Metric/MetricGroup'
import Metric from '../../ui/elements/Metric/Metric'
import SocialUrlUtil from '../../utils/SocialUrlUtil'

interface IParams {
  social?: string
  groupID?: string
  screenName?: string
  tags?: Array<string>
}

interface IProps {
  params?: ParamsProps<IParams>
  profileStore?: ProfileStore
  ratingTopCommunityStore?: RatingTopCommunityStore
  ratingTagsStore?: RatingTagsStore
}

/**
 * Страница Statistics
 */

@withParams
@inject(Stores.PROFILE_STORE, Stores.RATING_TOP_COMMUNITY_STORE, Stores.RATING_TOP_STORE, Stores.RATING_TAGS_STORE)
@observer
class RatingDetailPage extends Component<IProps> {

  constructor (props: IProps) {
    super(props)

    this.load()
  }

  componentDidUpdate (prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    this.load()
  }

  load = () => {
    const { params, ratingTopCommunityStore, ratingTagsStore } = this.props
    const socialType = params.social.toUpperCase()
    ratingTopCommunityStore.load(socialType, params.groupID, params.screenName)
    ratingTagsStore.load(socialType,[])
  }

  render (): JSX.Element {
    const { params, profileStore, ratingTopCommunityStore, ratingTagsStore } = this.props
    const isDeveloper = profileStore.profile.role === 'developer'
    const isLoading = ratingTopCommunityStore.isLoading
    const community = ratingTopCommunityStore.community
    const statistics = ratingTopCommunityStore.statistics

    if (!isDeveloper && community?.cid) {
      const socialURL = new SocialUrlUtil(community.url)
      const screenName = socialURL.getScreenName()
      const toUrl = `https://jagajam.com/app/rating/detail/${community.cid}?screenName=${screenName}`
      params.changeUrl(toUrl)

      return <a href={toUrl}>{toUrl}</a>
    }

    return (
      <LiteLayout>
        <Helmet>
          <title>JagaJam Rating - {!isLoading ? community.name : ''}</title>
        </Helmet>

        <Segment size={0}>
          <Toolbar2>
            <ButtonText icon='arrow_left' to={`/rating/${params.social}`}>Назад к рейтингу</ButtonText>
            { isDeveloper && !isLoading &&
            <ButtonText color='red' onClick={() => window.open(`/approval?cid=${this.props.ratingTopCommunityStore.community.cid}`, '_blank')}>
              Изменить теги
            </ButtonText> }
          </Toolbar2>
        </Segment>

        <Segment size={3}>
          {!isLoading && <RatingInfo
            image={community.image}
            name={community.name}
            url={community.url}
            usersCount={community.usersCount}
            position={community.position}
            tags={community.tags.map(tag => ({ ...tag, name: ratingTagsStore.getName(tag.tagID) }))}
            socials={community.socials}
            description={community.description}
            onSelectTag={(tagID) => params.changeUrl(`/rating/${params.social}`,{ tags:[...new Set([community.tags[0].tagID, tagID])] }) }
          />}
          {isLoading && <Loading size={285}>Анализируем страницу</Loading>}
        </Segment>

        <Segment size={1}>
          <AddTags />
        </Segment>

        <Segment size={10}>
          <Title center text='Динамика подписчиков'/>
          <Segment size={2}/>
          <Description center size='big'>
            Посмотрите, как ежедневно изменяется количество подписчиков на странице. Этот график даёт лишь общее представление об эффективности контента.
          </Description>
        </Segment>

        <Segment size={5}>
          <LineWidgetChart
            data={!isLoading ? statistics.usersCountRetrospective.map(statistic => ({ date: moment(statistic.date).valueOf(), value: statistic.value })) : []}
            color='#186AFF'
            loading={isLoading}
            height={300}
          />
        </Segment>

        <Segment size={10}>
          <Title center text='Изменение количества подписчиков'/>
          <Segment size={2}/>
          <Description center size='big'>
            Здесь можно увидеть как недавние события на странице влияют на количество пользователей и сделать соответствующие выводы.
          </Description>
        </Segment>

        <Segment size={5}>
          {!isLoading && (
            <RatingMetricGroup>
              <RatingMetric name='За последний день' value={statistics.usersCountDeltaDay}/>
              <RatingMetric name='За последние 7 дней' value={statistics.usersCountDeltaWeek}/>
              <RatingMetric name='За последние 30 дней' value={statistics.usersCountDeltaMonth}/>
            </RatingMetricGroup>
          )}
          {isLoading && <Loading size={130}>Сравниваем показатели</Loading>}
        </Segment>

        <Segment size={10}>
          <Title center text='Обзор основных показателей'/>
          <Segment size={2}/>
          <Description center size='big'>
            {`Тут мы собрали основные данные по странице.
            Чтобы увидеть все показатели — достаточно [добавить страницу](https://suite.jagajam.com?addCommunityType=my&addCommunity=true&addUrl=${community?.url}) в JagaJam Suite. В течение \r
            14 дней вы можете бесплатно пользоваться сервисом.`}
          </Description>
        </Segment>

        <Segment size={5}>
          <MetricGroup>
            <Metric
              big
              title='Подписчики'
              value={statistics?.usersCount}
              deltaValue={statistics?.usersCountDeltaMonth}
              tooltipTitle='Количество подписчиков'
              tooltipText='Общее количество подписчиков на странице и их изменение за выбранный период.'
            />
            {['FB', 'INST', 'TT', 'TW', 'VK', 'OK', 'YT', 'YZ'].includes(community?.socialType) && <Metric
              locked
              big
              title='Реакции'
              value={0}
              addUrl={community?.url}
            />}
            {['FB', 'INST', 'TT', 'TW', 'VK', 'OK', 'YT', 'YZ'].includes(community?.socialType) && <Metric
              locked
              big
              title='Вовлеченность'
              value={0}
              addUrl={community?.url}
            />}
            {['FB', 'INST', 'TT', 'TW', 'VK', 'OK', 'YT'].includes(community?.socialType) && <Metric
              locked
              big
              title='Лайки'
              value={0}
              addUrl={community?.url}
            />}
            {['FB', 'INST', 'TT', 'VK', 'OK', 'YT', 'YZ'].includes(community?.socialType) && <Metric
              locked
              big
              title='Комментарии'
              value={0}
              addUrl={community?.url}
            />}
            {['FB', 'TT', 'TW', 'VK', 'OK'].includes(community?.socialType) && <Metric
              locked
              big
              title='Репосты'
              value={0}
              addUrl={community?.url}
            />}
            {['FB', 'INST', 'TG', 'TT', 'TW', 'VK', 'OK', 'RT', 'YT', 'YZ'].includes(community?.socialType) && <Metric
              locked
              big
              title='Посты'
              value={0}
              addUrl={community?.url}
            />}
            {['TG', 'TT', 'VK', 'RT', 'YT', 'YZ'].includes(community?.socialType) && <Metric
              locked
              big
              title='Просмотры'
              value={0}
              addUrl={community?.url}
            />}
          </MetricGroup>
        </Segment>

        <Segment size={10}>
          <ADV/>
        </Segment>

        <Segment size={10}>
          <Title center text='Похожие страницы'/>
          <Segment size={2}/>
          <Description center size='big'>
            Мы подобрали несколько похожих страниц. Возможно они будут интересны для вас, рекомендуем изучить их или добавить в любой из наших сервисов для конкурентного анализа.
          </Description>
        </Segment>

        <Segment size={5}>
          <RatingMetricGroup>
            {!isLoading && statistics.similar.map(community => (
              <LookAlike
                key={community.name}
                image={community.image}
                name={community.name}
                usersCount={community.usersCount}
                to={`/rating/${community.socialType.toLowerCase()}/${community.screenName}/${community.groupID}`}
              />
            ))}
            {isLoading && <Loading size={220}>Ищем похожие страницы</Loading>}
          </RatingMetricGroup>
        </Segment>

        <Segment size={7}/>
      </LiteLayout>
    )
  }
}

export default RatingDetailPage
