import React, { Component, ReactNode } from 'react'
import cx from 'classnames'

import './Image.scss'

interface IProps {
  /**
   * Адрес
   */
  src?: string
  /**
   * Адрес для retina
   */
  srcSet?: string
  /**
   * Альтернативный текст
   */
  alt?: string
  /**
   * Дополнительные классы
   */
  className?: string
  /**
   * Плейсхолдер в случае ошибки загрузки
   */
  noImage?: string
  /**
   * Круглая картинка
   */
  round?: boolean
  /**
   * Рамка вокруг картинки
   */
  border?: boolean
  /**
   * Элементы
   */
  children?: ReactNode
}

/**
 * Элемент Image
 */
class Image extends Component<IProps> {
  static defaultProps = {
    alt: '',
    src: '',
    srcSet: ''
  }

  state = {
    isError: false
  }

  onError = (e) => {
    e.target.onerror = null
    e.target.src = this.props.noImage
    this.setState({
      isError: true
    })
  }

  render (): JSX.Element {
    let { src, srcSet, alt, className, round, border, noImage, children } = this.props
    const { isError } = this.state

    if (src) src = src.replace('http://', 'https://')

    const classes = cx('image', className, {
      'image--round': round,
      'image--border': border,
      'image--error': isError
    })

    return (
      <div className={classes}>
        <img
          src={src ? String(src) : noImage}
          srcSet={srcSet}
          onError={noImage && this.onError}
          alt={alt}
        />
        {children}
      </div>
    )
  }
}

export default Image
