import React, { Component, ReactNode } from 'react'

import './RatingMetricGroup.scss'

interface IProps {
  children: ReactNode
}

/**
 * Элемент RatingMetricGroup.
 */

class RatingMetricGroup extends Component<IProps> {

  render(): JSX.Element {

    return (
      <div className='rating-metric-group'>
        {this.props.children}
      </div>
    )
  }
}

export default RatingMetricGroup
