import React, { Component, MouseEventHandler } from 'react'

import './FloatingRow.scss'
import ButtonText from '../ButtonText/ButtonText'

interface IProps {
  /**
   * Обработчик клика кнопки подтверждения
   */
  onConfirmClick?: MouseEventHandler
  /**
   * Обработчик клика кнопки отмены
   */
  onCancelClick?: MouseEventHandler
  /**
   * Текст кнопки подтверждения
   */
  confirmBtnText: string
  /**
   * Текст кнопки отмены
   */
  cancelBtnText?: string
}

/**
 * Элемент FloatingRow.
 * Плаваюее окно внизу экрана для выполнения действий
 */

class FloatingRow extends Component<IProps> {

  render (): JSX.Element {
    const { onConfirmClick, onCancelClick, confirmBtnText, cancelBtnText } = this.props
    return (
      <div className='floating-row'>
        { cancelBtnText && <ButtonText onClick={onCancelClick} className='floating-row__button'>{cancelBtnText}</ButtonText> }
        <ButtonText onClick={onConfirmClick} color='green' className='floating-row__button'>{confirmBtnText}</ButtonText>
      </div>
    )
  }
}

export default FloatingRow
