import React, { Component, MouseEventHandler, ReactNode } from 'react'
import cx from 'classnames'

import Icon from '../Icon/Icon'
import { IIcon } from '../Icon/Icons'
import BaseButtonText from './BaseButtonText'

import './ButtonText.scss'

type Size = 'small' | 'middle' | 'big' | 'awesome'
type Color = 'grey' | 'blue' | 'red' | 'green' | 'transparent'
type Type = 'button' | 'submit'
type Position = 'left' | 'center' | 'right'

interface IProps {
  /**
   * Иконка
   */
  icon?: IIcon | string
  /**
   * Текст кнопки
   */
  children?: ReactNode
  /**
   * Размер
   */
  size?: Size
  /**
   * Цвет
   */
  color?: Color
  /**
   * Активна ли кнопка
   */
  disabled?: boolean
  /**
   * Обработчик клика
   */
  onClick?: MouseEventHandler
  /**
   * Ссылка
   */
  to?: string
  /**
   * Тип кнопки
   */
  type?: Type
  /**
   * Состояние активной кнопки (нажатой)
   */
  active?: boolean
  /**
   * Дополнительные классы
   */
  className?: string
  /**
   * Расположение текста внутри кнопки
   */
  position?: Position
  /**
   * На всю ширину
   */
  full?: boolean
}

/**
 * Элемент ButtonText
 * Кнопка
 */

class ButtonText extends Component<IProps> {

  static defaultProps = {
    size: 'big',
    color: 'grey',
    type: 'button',
    position: 'center'
  }

  render (): JSX.Element {
    const { children, onClick, to, size, color, icon, disabled, type, active, className, position, full } = this.props

    const classes = cx('button-text', className, {
      [`button-text--${size}`]: size,
      [`button-text--${color}`]: color,
      [`button-text--${position}`]: position,
      'button-text--active': active,
      'button-text--full': full,
      'button-text--disabled': disabled
    })

    return (
      <BaseButtonText to={to} className={classes} type={type} onClick={!disabled && onClick}>
        {icon && <Icon className='button-text__icon' icon={icon} />}
        {children && <span>{children}</span>}
      </BaseButtonText>
    )
  }
}

export default ButtonText
