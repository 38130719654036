import React, { Component, ReactNode } from 'react'

import './HeaderAccountBlock.scss'

interface IProps {
  children: ReactNode
}

/**
 * Элемент HeaderAccountBlock.
 * Зона хэдэра справа от меню
 */
class HeaderAccountBlock extends Component<IProps> {
  render (): ReactNode {

    const { children } = this.props

    return (
        <div className='header-account-block'>
          {children}
        </div>
    )
  }
}

export default HeaderAccountBlock
