
const icons = {
  add: require('./img/add.svg'),
  add_page: require('./img/add-page.svg'),
  admin: require('./img/admin.svg'),
  analyze: require('./img/analyze.svg'),
  analyze_static: require('./img/analyze_static.svg'),
  arrow_left: require('./img/arrow-left.svg'),
  auto: require('./img/auto.svg'),
  attention_nt: require('./img/attention_nt.svg'),
  back: require('./img/back.svg'),
  beauty: require('./img/beauty.svg'),
  beta: require('./img/beta.svg'),
  burger: require('./img/burger.svg'),
  c_error: require('./img/c_error.svg'),
  comment: require('./img/comment.svg'),
  ch: require('./img/ch.svg'),
  ch_colored: require('./img/ch_colored.svg'),
  change_negative: require('./img/change-negative.svg'),
  change_positive: require('./img/change-positive.svg'),
  chart_absolute: require('./img/chart-absolute.svg'),
  chart_dynamic: require('./img/chart-dynamic.svg'),
  chart_relative: require('./img/chart-relative.svg'),
  check: require('./img/check.svg'),
  clock: require('./img/clock.svg'),
  close: require('./img/close.svg'),
  close_circle: require('./img/close-circle.svg'),
  community: require('./img/community.svg'),
  compare: require('./img/compare.svg'),
  complete: require('./img/complete.svg'),
  connected: require('./img/connected.svg'),
  control_delete: require('./img/control_delete.svg'),
  control_clock: require('./img/control-clock.svg'),
  credit_card: require('./img/credit-card.svg'),
  cross: require('./img/cross.svg'),
  d_calendar: require('./img/d-calendar.svg'),
  dashboard: require('./img/dashboard.svg'),
  dashboard_domain: require('./img/dashboard_domain.svg'),
  dateA: require('./img/dateA.svg'),
  dateA_simple: require('./img/dateAsimple.svg'),
  dateB: require('./img/dateB.svg'),
  dateB_simple: require('./img/dateBsimple.svg'),
  date_start: require('./img/date_start.svg'),
  date_finish: require('./img/date_finish.svg'),
  diff: require('./img/diff.svg'),
  dot: require('./img/dot.svg'),
  down: require('./img/down.svg'),
  download: require('./img/download.svg'),
  duplicate: require('./img/duplicate.svg'),
  electronics: require('./img/electronics.svg'),
  error_nt: require('./img/error_nt.svg'),
  export: require('./img/export.svg'),
  eye_small: require('./img/eye-small.svg'),
  fb: require('./img/facebook.svg'),
  fb_bg: require('./img/fb-bg.svg'),
  fb_colored: require('./img/fb-colored.svg'),
  fashion: require('./img/fashion.svg'),
  filter: require('./img/filter.svg'),
  finance: require('./img/finance.svg'),
  food_retail: require('./img/food-retail.svg'),
  retail_food: require('./img/retail-food.svg'),
  gear: require('./img/gear.svg'),
  geo_pin: require('./img/geo-pin.svg'),
  geo_small: require('./img/geo-small.svg'),
  global: require('./img/global.svg'),
  gp: require('./img/google.svg'),
  grade_a_plus: require('./img/grade-a+.svg'),
  grade_a_plus_colored: require('./img/grade-a+-colored.svg'),
  grade_a: require('./img/grade-a.svg'),
  grade_a_colored: require('./img/grade-a-colored.svg'),
  grade_b: require('./img/grade-b.svg'),
  grade_b_colored: require('./img/grade-b-colored.svg'),
  grade_c: require('./img/grade-c.svg'),
  grade_c_colored: require('./img/grade-c-colored.svg'),
  grade_d: require('./img/grade-d.svg'),
  grade_d_colored: require('./img/grade-d-colored.svg'),
  graph: require('./img/graph.svg'),
  group: require('./img/group.svg'),
  heart: require('./img/heart.svg'),
  heart_small: require('./img/heart-small.svg'),
  help: require('./img/help.svg'),
  ig: require('./img/instagram.svg'),
  ig_: require('./img/instagram_.svg'),
  ig_bg: require('./img/inst-bg.svg'),
  ig_colored: require('./img/inst-colored.svg'),
  // Instagram алиас для совместимости с API
  inst: require('./img/instagram.svg'),
  inst_: require('./img/instagram_.svg'),
  inst_bg: require('./img/inst-bg.svg'),
  inst_colored: require('./img/inst-colored.svg'),
  //
  rt: require('./img/rt.svg'),
  rt_colored: require('./img/rt_colored.svg'),
  jj: require('./img/jj.svg'),
  jagajam_text: require('./img/jagajam-text.svg'),
  left: require('./img/left.svg'),
  lin: require('./img/linkdin.svg'),
  lin_colored: require('./img/linkedin-colored.svg'),
  loader: require('./img/loader.svg'),
  loading: require('./img/loading.svg'),
  locker: require('./img/locker.svg'),
  logo_rating_ru: require('./img/logo_rating_ru.svg'),
  logout: require('./img/logout.svg'),
  logout_circle: require('./img/logout-circle.svg'),
  media: require('./img/media.svg'),
  minus: require('./img/minus.svg'),
  more: require('./img/more.svg'),
  more_alt: require('./img/more-alt.svg'),
  move_tool: require('./img/move_tool.svg'),
  mt: require('./img/my-target.svg'),
  na: require('./img/no-data.svg'),
  no_picture: require('./img/no-picture.svg'),
  notification: require('./img/notification.svg'),
  notification_active: require('./img/notification_active.svg'),
  ok: require('./img/odnoklasniki.svg'),
  ok_bg: require('./img/ok-bg.svg'),
  ok_colored: require('./img/ok-colored.svg'),
  organic: require('./img/organic.svg'),
  paid: require('./img/paid.svg'),
  pdf: require('./img/pdf.svg'),
  pi: require('./img/pinterest.svg'),
  pi_colored: require('./img/pinterest-colored.svg'),
  page: require('./img/page.svg'),
  pencil: require('./img/pencil.svg'),
  person: require('./img/person.svg'),
  pipet: require('./img/pipet.svg'),
  plus: require('./img/plus.svg'),
  ppt: require('./img/ppt.svg'),
  pptx: require('./img/pptx.svg'),
  qiwi: require('./img/qiwi.svg'),
  retail: require('./img/retail.svg'),
  rename: require('./img/rename.svg'),
  right: require('./img/right.svg'),
  rouble: require('./img/rouble.svg'),
  rule_edit: require('./img/rule-edit.svg'),
  rule_remove: require('./img/rule-remove.svg'),
  rules: require('./img/rules.svg'),
  sber_logo: require('./img/sber_logo.svg'),
  search: require('./img/search.svg'),
  select_dashboard: require('./img/select-dashboard.svg'),
  share_in: require('./img/share-in.svg'),
  share_out: require('./img/share-out.svg'),
  shared_tag: require('./img/shared-tag.svg'),
  sigma: require('./img/sigma.svg'),
  slider_next: require('./img/slider_next.svg'),
  slider_prev: require('./img/slider_prev.svg'),
  sort_asc: require('./img/sort-asc.svg'),
  sort_desc: require('./img/sort-desc.svg'),
  star: require('./img/star.svg'),
  star_fill: require('./img/star_fill.svg'),
  stats_down: require('./img/stats_down.svg'),
  stats_up: require('./img/stats_up.svg'),
  success: require('./img/success.svg'),
  success_circle: require('./img/success-circle.svg'),
  success_nt: require('./img/success-nt.svg'),
  suite_logo: require('./img/suite_logo.svg'),
  summary_colored: require('./img/summary_colored.svg'),
  tariff_promise: require('./img/tariff-promise.svg'),
  tariff_wait: require('./img/tariff-wait.svg'),
  tbwa_logo: require('./img/tbwa_logo.svg'),
  text_small: require('./img/text-small.svg'),
  text_jagajam: require('./img/text_jagajam.svg'),
  text_rating: require('./img/text_rating.svg'),
  text_suite: require('./img/text_suite.svg'),
  tag: require('./img/tag.svg'),
  tg: require('./img/tg.svg'),
  tg_bg: require('./img/tg-bg.svg'),
  tg_colored: require('./img/telegram-colored.svg'),
  tone: require('./img/tone.svg'),
  trash: require('./img/trash.svg'),
  tt: require('./img/tt.svg'),
  tt_bg: require('./img/tt-bg.svg'),
  tt_colored: require('./img/tt_colored.svg'),
  tw: require('./img/twitter.svg'),
  tw_bg: require('./img/tw-bg.svg'),
  tw_colored: require('./img/twitter-colored.svg'),
  undetected: require('./img/undetected.svg'),
  up: require('./img/up.svg'),
  viewmode_marks: require('./img/viewmode-marks.svg'),
  viewmode_reports: require('./img/viewmode-reports.svg'),
  vk: require('./img/vk.svg'),
  vk_bg: require('./img/vk-bg.svg'),
  vk_colored: require('./img/vk-colored.svg'),
  webmoney: require('./img/web-money.svg'),
  widget_admin: require('./img/widget_admin.svg'),
  x: require('./img/X.svg'),
  xls: require('./img/xls.svg'),
  xlsx: require('./img/xlsx.svg'),
  yandex_money: require('./img/yandex-money.svg'),
  yt: require('./img/youtube.svg'),
  yt_bg: require('./img/yt-bg.svg'),
  yt_colored: require('./img/youtube-colored.svg'),
  yz: require('./img/yz.svg'),
  yz_bg: require('./img/yz-bg.svg'),
  yz_colored: require('./img/yz-colored.svg'),
  reply: require('./img/reply.svg'),
  icon_comment: require('./img/icon-comment.svg'),
  visa: require('./img/visa.svg'),
  mastercard: require('./img/mastercard.svg')
}

export type IIcon = keyof typeof icons

export default icons
