import React, {Component, ReactNode} from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import './HeaderMenuLink.scss'

interface IProps {
  /**
   * Ссылка
   */
  to: string
  /**
   * Текст
   */
  children: ReactNode
  /**
   * Открывать в новом окне
   */
  newTab?: boolean
}

/**
 * Элемент HeaderMenuLink
 */
class HeaderMenuLink extends Component<IProps> {
  render (): JSX.Element {
    const { children } = this.props
    let { to, newTab } = this.props

    if (to['0'] === '#') {
      return <AnchorLink className='header-menu-link' href={to} offset={100}>{children}</AnchorLink>
    }

    return <NavLink target={newTab && '_blank'} className='header-menu-link' activeClassName='header-menu-link--active' to={to}>{children}</NavLink>
  }
}

export default HeaderMenuLink
