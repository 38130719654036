import { action, makeAutoObservable } from 'mobx'
import moment, { Moment } from 'moment'
import RootStore from './RootStore'
import APIClient from '../lib/APIClient'

interface IAccount {
  accountID: string
  name: string
  image: string
  currentCommunities: number
  communities: Array<IAccountCommunity>
}

interface IAccountCommunity {
  communityID: string
  socialType: string
  name: string
  image: string
  url: string
  dateLoadPosts: Moment
  isAdmin: boolean
}

interface ISlide {
  id: string
  accountID: string
  type: string
  accountImage: string
  accountName: string
  communityID: string
  communityIDs: Array<string>
  communityImage: string
  communityName: string
  communitySocialType: string
  communityIsAdmin: string
}

export default class AccountsStore {
  public isLoading: boolean = false
  public accounts: Array<IAccount> = []
  public slides: Array<ISlide> = []
  public currentAccount: IAccount = null

  constructor () {
    makeAutoObservable(this)
  }

  @action
  public async load (silentLoading: boolean = false): Promise<void> {
    if (!silentLoading) this.isLoading = true
    try {
      const data = await APIClient.get('dashboards', {
        token: RootStore.profileStore.token
      })
      console.log(data.data.data)
      this.accounts = data.data.data.map(dashboard => {
        const account: IAccount = {
          accountID: dashboard.dashboard_id,
          name: dashboard.name,
          image: dashboard.image,
          communities: dashboard.items.filter(community => community.type === 'community').map(community => ({
            communityID: community.cid,
            socialType: community.soctype,
            name: community.name,
            image: community.image,
            url: community.url,
            dateLoadPosts: moment(community.time_posts_start),
            isAdmin: community.is_insights
          })),
          currentCommunities: 0
        }

        account.currentCommunities = account.communities.length

        return account
      })

      // Собираем слайды
      const slides = []
      let lastPostsIndex = 1

      this.accounts.forEach(account => {
        let isAdmins = false
        account.communities.forEach(community => {
          if (community.isAdmin) isAdmins = true;

          ['lastPosts', 'posts', 'bestStories', 'lastVotes'].forEach(slideType => {
            if ((slideType === 'bestStories' || slideType === 'lastVotes') && (!['VK', 'FB', 'INST'].includes(community.socialType) || !community.isAdmin)) return

            if (slideType === 'lastPosts') {
              lastPostsIndex = lastPostsIndex === 1 ? 2 : 1
              slideType = `lastPosts${lastPostsIndex}`
            }

            slides.push({
              id: `${account.accountID}${slideType}${community.communityID}`,
              accountID: account.accountID,
              type: slideType,
              accountImage: account.image,
              accountName: account.name,
              communityID: community.communityID,
              communityImage: community.image,
              communityName: community.name,
              communitySocialType: community.socialType,
              communityIsAdmin: community.isAdmin
            })
          })
        })

        if (isAdmins) {
          slides.push({
            id: `${account.accountID}summary`,
            accountID: account.accountID,
            type: 'summary',
            accountImage: account.image,
            accountName: account.name,
            communityIDs: account.communities.filter(community => community.isAdmin).map(community => community.communityID)
          })
        }
      })
      this.slides = slides
      //

      this.setAccount()
    } catch (err) {
      console.error(err, err.response.status, err.response)
    } finally {
      this.isLoading = false
    }
  }

  @action
  public setAccount (accountID: string = null): void {
    if (!accountID) {
      const currentAccountID = localStorage.getItem('currentAccountID')
      if (currentAccountID) {
        this.currentAccount = this.accounts.find(item => item.accountID === currentAccountID)
      } else {
        this.currentAccount = this.accounts[0]
      }
    } else {
      this.currentAccount = this.accounts.find(item => item.accountID === accountID)
      localStorage.setItem('currentAccountID', accountID)
    }
  }
}
