import React, { Component, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '../Icon/Icon'

import './HeaderLogo.scss'

/**
 * Элемент HeaderLogo
 */
class HeaderLogo extends Component {

  render (): ReactNode {
    return (
      <NavLink to='/' className='header-logo'>
        <Icon icon='jj' className='header-logo__icon' />
        <Icon icon='logo_rating_ru' className='header-logo__name' />
      </NavLink>
    )
  }
}

export default HeaderLogo
