import * as Highcharts from 'highcharts'
import React, { Component } from 'react'
import cx from 'classnames'

import BaseChart from './BaseChart'
import Icon from '../../elements/Icon/Icon'

import './Chart.scss'
import PolarChart from './PolarChart'
import NoData from '../../elements/NoData/NoData'

interface IProps {
  /**
   * Подписи оси Y
   */
  yTitle?: string
  /**
   * Подписи оси X
   */
  xTitle?: string
  /**
   * Массив серий
   */
  series: Array<Highcharts.SeriesOptionsType>
  /**
   * Функция форматирования tooltip
   */
  tooltip: Highcharts.TooltipFormatterCallbackFunction
  /**
   * Состояние загрузки
   */
  loading?: boolean
  /**
   * Данные для графика отсутствуют
   */
  noData?: boolean
  /**
   * Полярный график
   */
  polar?: boolean
  /**
   * Цвет графика
   */
  color?: string
  /**
   * Высота графика
   */
  height?: number
}

/**
 * Модуль Chart
 * Рендеринг графиков
 */
class Chart extends Component<IProps> {
  render (): JSX.Element {
    const {
      series, tooltip, loading, noData, xTitle, yTitle, polar, color, height
    } = this.props

    const classes = cx('chart', {
      'chart--loading': loading,
      'chart--no-data': noData
    })
    return (
      <div className={classes}>
        <div className='chart__content'>
          {!loading && !noData && !polar && <BaseChart xTitle={xTitle} yTitle={yTitle} series={series} color={color} tooltip={tooltip} height={height} />}
          {!loading && !noData && polar && <PolarChart xTitle={xTitle} yTitle={yTitle} series={series} tooltip={tooltip} />}
          {loading && <Icon icon='loader' />}
          {!loading && noData &&
            <NoData />
          }
        </div>
      </div>
    )
  }
}
export default Chart
